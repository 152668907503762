<template>
  <div class="users-list-container">
    <table class="selected-table" v-if="selectedList.length">
      <tr
        v-for="member in selectedList"
        :key="member.id"
      >
        <td class="selected-table-row">
          <tr>
            <td><account-icon /></td>
            <td class="table-content">
              <tr><td style="font-weight: bold;">{{ member.brand_name }}</td></tr>
              <tr>
                <td>
                  <small style="line-height: 9px;">
                    {{ member.first_name }} {{ member.last_name }}
                    {{ member.role && `(${member.role})` }}
                  </small>
                </td>
              </tr>
            </td>
          </tr>
        </td>
        <td class="remove-user" @click="removeItem(member)"><popup-close :fillOpacity="0" /></td>
      </tr>
    </table>
    <input
      v-if="searchField"
      v-model="searchValue"
      class="search-field"
      type="text"
      :placeholder="placeholder"
      id="search"
      name="search"
    />
    <table class="table" v-if="listToShow.length">
      <tr
        v-for="member in listToShow"
        :key="member.id"
      >
        <td class="table-row" @click="addItem(member)">
          <tr>
            <td><account-icon /></td>
            <td class="table-content">
              <tr><td style="font-weight: bold;">{{ member.brand_name }}</td></tr>
              <tr>
                <td>
                  <small style="line-height: 9px;">
                    {{ member.first_name }} {{ member.last_name }}
                    {{ member.role && `(${member.role})` }}
                  </small>
                </td>
              </tr>
            </td>
          </tr>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import AccountIcon from "../assets/icons/AccountIcon.vue";
import PopupClose from "../assets/icons/PopupClose.vue";

export default {
  name: "UsersListView",
  components: {
    AccountIcon,
    PopupClose
  },
  props: {
    searchField: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    membersList: {
      type: Array,
      default: () => []
    },
    selectedList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    listToShow() {
      if(this.searchValue){
        const regEx = new RegExp(`^${this.searchValue}`, 'i');
        return this.membersList.filter(member => regEx.test(member.brand_name) || regEx.test(`${member.first_name} ${member.last_name}`) || regEx.test(member.last_name));
      }
      return this.membersList;
    }
  },
  data: () => ({
    searchValue: ''
  }),
  methods: {
    removeItem(item) {
      this.$emit('remove-item', item);
    },
    addItem(item) {
      this.$emit('add-item', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.users-list-container {
  height: 350px;
  overflow: auto;
  margin: auto;
}
.table {
  width: 100%;
  margin: 40px 0 40px 0;
  border-bottom: 1px solid $tableBorder;
}
.selected-table-row {
  width: 100%;
  margin: 40px 0 40px 0;
  border: none;
}
.table-row {
  border-top: 1px solid $tableBorder;
  padding-top: 10px;
  text-align: left;
  cursor: pointer;
}
.selected-table-row {
  border: none;
  padding-top: 10px;
  text-align: left;
}
.table-content {
  padding-left: 18px;
  vertical-align: top;
  color: $tableBorder
}
.search-field {
  margin-top: 15px;
}
input[type=text], select {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 2.5px solid $primaryDark;
  color: #492a67;
}
input:focus {
  outline: none;
}
.remove-user {
  text-align: right !important;
  cursor: pointer;
}
::placeholder {
  color: $primaryDark;
  font-weight: 400;
}
</style>
