<template>
    <div v-if="loading" class="text-center" style="height: 500px;">
        <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <div v-else class="main_container">
        <v-row class="mb-1">
            <v-col>
                <div class="message-div">
                    <div class="message-waiting" v-if="needTraceApproval"><span>Waiting For Buyer Approval...</span></div>
                    <div class="message-done" v-else-if="isPublished"><span>Available for general public...</span></div>
                    <v-divider/>
                    <div v-if="rejectionComment">
                        <div class="message-rejected"><span>Latest Updates Rejected...</span>
                            <md-button class="md-raised" style="background-color: #FA735F; color: #EEF0FB;"
                                @click="reviewBoxClose = true">See Comment
                            </md-button>
                        </div>
                        <v-dialog
                            max-width="500"
                            v-model="reviewBoxClose"
                            content-class="v-dialog-custom"
                        >
                            <v-card>
                                <v-card-title>
                                    <p class="display-2 black--text underline text-left">
                                        Buyer Comment
                                    </p>
                                </v-card-title>
                                <v-divider/>
                                <v-card-text height="1000px" class="pt-0">
                                    <v-container class="py-0">
                                        <v-form ref="CompanyDetails" class="mt-5">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-textarea
                                                        v-model="rejectionComment"
                                                        readonly
                                                        outlined
                                                        dense
                                                        color="purple darken-3"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                <md-button class="md-raised md-primary" style="background-color: #582963; color: #EEF0FB;" @click="reviewBoxClose = false">Close</md-button>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </div>
            </v-col>
            <v-col>
                <div class="url-div">
                    <v-icon style="color: #501D5F; transform: rotate(120deg);">link</v-icon>
                    <span class="mr-2 ml-2" style="border-left: 0.5px solid #501D5F; height: 100px;"></span>
                    <span class="url-span" id="toCopy">{{ publicLink }}</span><v-icon :style="copied ? 'color: #70D0CE;' : 'color: #501D5F;'" @click="copying">{{copied ? "done_all" : "file_copy"}}</v-icon>
                </div>
            </v-col>
            <md-button class="view_certifications md-raised" @click="AllCertifications">View Certifications</md-button>
            <md-button class="qr-code-button md-raised success" style="color: #EEF0FB;" @click="openQrCode">QR Code</md-button>
            <v-dialog v-model="qrDialog" width="unset" class="qr-code-dialog">
                <qr-code
                    class="qr-code-comp"
                    :qr="qr"
                    :styleNumber="styleNumber"
                    :trackingCode="trackingCode"
                    centerAlign
                />
            </v-dialog>
        </v-row>
        <div v-if="traceLayout || isPublished" class="section request-container">
            <div v-if="traceLayout" class="request-item">
                <div>Buyer requested to include:</div>
                <div>
                    <ul>
                        <li>Care Instructions</li>
                        <li>Composition</li>
                        <li>Sustainability, Circularity and Repairs</li>
                        <li v-if="traceLayout.supply_chain">Supply Chain</li>
                        <li v-if="traceLayout.bom">Bill of Material</li>
                    </ul>
                </div>
            </div>
            <div v-if="isPublished" class="request-item">
                <div>Published trace may include: (if available)</div>
                <div>
                    <ul>
                        <li>Care Instructions</li>
                        <li>Composition</li>
                        <li>Sustainability, Circularity and Repairs</li>
                        <li v-if="
                            traceLayout && traceLayout.supply_chain_published != null ?
                            traceLayout.supply_chain_published : true
                        ">Supply Chain</li>
                        <li v-if="
                            traceLayout && traceLayout.bom_published != null ?
                            traceLayout.bom_published : false
                        ">Bill of Material</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grey lighten-5 section" style="padding : 20px;">
            <v-form @submit.prevent="save">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            id="product-name-field"
                            class="text-field"
                            label="Product name"
                            v-model="model.productName"
                            :error-messages="productNameError || null"
                            @input="typingProductName"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <hr/>
                <v-row>
                    <v-col v-if="buyerLogoImg" cols="2" align="right">
                        <div>
                            <img style="max-width: 100px;" :src="buyerLogoImg"/>
                        </div>
                    </v-col>
                    <v-col cols="4">
                            <v-file-input
                                class="text-field"
                                accept="image/png, image/jpeg, image/bmp"
                                small-chips
                                label="Buyer Logo"
                                v-model="buyerLogo"
                                outlined
                                dense
                                @change="previewBuyerLogo"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            class="text-field"
                            label="Link"
                            placeholder="http://seamlesssource.com"
                            v-model="buyerLink"
                            maxlength="255"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                            <md-button class="logo_btn" @click="addBuyerLogo">Update Logo</md-button>
                    </v-col>
                </v-row>
                <hr/>
                <div v-for="(item, index) in model.itemDetails" :key="index">
                    <v-row no-gutters>
                        <v-col cols="3">
                        <div class="lable_size">
                            <label>{{item.display_name}}</label>
                        </div>
                        </v-col>
                        <v-col cols="5" sm="" v-if="item.supplier_status">
                        <v-row class="radio_group_size">
                            <v-radio-group
                            v-model="item.details.supplier_status"
                            row
                            >
                            <v-radio
                                v-for="(supplierStatus) in supplierStatuses"
                                :key="supplierStatus.id"
                                :label="supplierStatus.name"
                                :value="supplierStatus.id"
                            ></v-radio>
                            </v-radio-group>
                        </v-row>
                        </v-col>
                        <v-col v-if="item.certification" cols="4">
                        <div style="padding-top:20px;">
                            <div class="certifications_container">
                                <span v-for="(certification, index) in item.details.certifications" :key="index">
                                    <v-chip outlined
                                            :name="certification.id"
                                            class="certifications_size"
                                            :for="certification.id"
                                    >
                                            <p class="font-weight-bold mt-4 mr-2 certifications_font_style" v-if="!certification.description && certification.display_name.length < 15">{{ certification.display_name | truncate(15, "...")}}</p>
                                            <v-tooltip v-else top color="#C2AAE8">
                                                <template v-slot:activator="{ on, attrs }">
                                                <p v-bind="attrs" v-on="on" class="font-weight-bold mt-4 mr-2 certifications_font_style">{{ certification.display_name | truncate(15, "...")}}</p>
                                                </template>
                                                <div class="description-container">
                                                    <div><h3>{{certification.display_name}}</h3></div>
                                                    <div class="mt-1">{{ certification.description }}</div>
                                                </div>
                                            </v-tooltip>
                                        <div v-if="!certification.verified">
                                            <v-tooltip bottom color="#70D0CE">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-check
                                                        name="verification"
                                                        icon
                                                        color="#582963"
                                                        @click="updateStatus(item.id, certification.id , true)"
                                                        x-small
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                                                    </v-check>
                                                </template>
                                                <span>Mark as verified</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="certification.verified">
                                            <v-tooltip bottom color="red">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-check
                                                        name="verification"
                                                        icon
                                                        color="red"
                                                        @click="updateStatus(item.id, certification.id , false)"
                                                        x-small
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon>mdi-checkbox-marked</v-icon>
                                                    </v-check>
                                                </template>
                                                <span>Mark as unverified</span>
                                            </v-tooltip>
                                        </div>
                                        <v-icon @click="removeCertification(item.id, certification.id)">mdi-close</v-icon>
                                    </v-chip>
                                </span>
                            </div>
                        </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" v-if="item.country">
                            <v-autocomplete
                                class="text-field"
                                clearable
                                append-icon="mdi-map"
                                :items="arr"
                                item-text="country"
                                item-value="country"
                                label="Country"
                                single-line
                                v-on:change="validateCountry(item.id)"
                                v-model="item.details.country"
                            ></v-autocomplete>
                            <div :id="`field-${item.id}`" class="countryError">
                            </div>
                        </v-col>
                        <v-col cols="4" v-if="item.region">
                        <v-text-field
                            class="text-field"
                            label="Region"
                            maxlength="255"
                            outlined
                            dense
                            clearable
                            v-model="item.details.region"
                        >
                        </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="item.certification">
                        <v-text-field
                            label="Certifications"
                            class="text-field"
                            v-model="item.searchQuery"
                            v-on:input="searchCertifications(item.searchQuery)"
                            maxlength="750"
                            outlined
                            type="text"
                            dense
                        ><template v-slot:append>
                            <v-fade-transition leave-absolute>
                            <v-progress-circular
                                v-if="loadingS == item.id"
                                size="24"
                                color="info"
                                indeterminate
                            ></v-progress-circular>
                            <v-icon @click="addUserTypeCertification(item.searchQuery, item.id)"
                            >add
                            </v-icon>
                            </v-fade-transition>
                        </template></v-text-field>
                        <v-card
                            v-if="item.searchQuery"
                            class="search-result"
                            tile
                        >
                            <div v-if="searchingCertifications" class="spinner-box">
                            <md-progress-bar class="bar_color" md-mode="indeterminate"></md-progress-bar>
                            <label>Getting matching certifications</label>
                            </div>
                            <label  v-else-if="!searchingCertifications && matchingCertifications.length === 0"
                            >No matching certifications found
                            </label>
                            <md-list v-if="!searchingCertifications">
                            <md-list-item style="margin-left:-20px;"
                                            v-for="matchingCertification in matchingCertifications"
                                            :key="matchingCertification.id"
                            >
                                <span class="certification_display_name">{{ matchingCertification.display_name}}</span>
                                <span>
                                    <md-button class="add_button" @click="addListCertification(matchingCertification, item.id)"><md-icon style="color:purple;">add</md-icon></md-button>
                                    <md-button class="remove_button" @click="removeDbCertification(matchingCertification ,item.id)"><md-icon style="color:red;">close</md-icon></md-button>
                                </span>
                            </md-list-item>
                            <md-list-item v-if="matchingCertifications.length && !viewAll">
                                <md-button class="view_all" @click="searchAll(item.searchQuery)">View All</md-button>
                            </md-list-item>
                            </md-list>
                        </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="item.number_of_employees">
                        <v-col cols="12">
                            <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    class="text-field"
                                    label="Number of Employees"
                                    outlined
                                    dense
                                    clearable
                                    type="number"
                                    min="0"
                                    v-model="item.details.number_of_employees"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-if="item.details.number_of_employees"
                                    id="employees-ratio-field"
                                    class="text-field"
                                    label="Percentage of Males"
                                    outlined
                                    dense
                                    clearable
                                    type="number"
                                    min="0"
                                    max="100"
                                    step=".01"
                                    v-model="item.details.number_of_males"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-if="item.details.number_of_employees"
                                    class="text-field"
                                    label="Percentage of Females"
                                    outlined
                                    dense
                                    clearable
                                    type="number"
                                    min="0"
                                    max="100"
                                    step=".01"
                                    v-model="item.details.number_of_females"
                                >
                                </v-text-field>
                            </v-col>

                                <v-col cols="3">
                                <v-text-field
                                  v-if="item.details.number_of_employees"
                                  class="text-field"
                                  label="Percentage of Others"
                                  outlined
                                  dense
                                  clearable
                                  type="number"
                                  min="0"
                                  max="100"
                                  step=".01"
                                  v-model="item.details.number_of_others"
                                >
                                </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" v-if="item.delivery_method">
                            <div>
                                <v-row class="radio_group_size">
                                    <v-radio-group
                                        v-model="item.details.delivery_method"
                                        row
                                    >
                                        <v-radio
                                            v-for="(method) in deliveryMethods"
                                            :key="method.id"
                                            :label="method.name"
                                            :value="method.id"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                    <hr/>
                </div>
                <v-row no-gutters>
                    <v-col cols="12">
                        <div class="lable_size">
                            <label>Fabric Composition</label>
                        </div>
                        <div>
                            <v-textarea
                                class="text-field"
                                v-model="model.fabricComposition"
                                maxlength="5000"
                                outlined
                                dense
                            >
                            </v-textarea>
                        </div>
                        <hr/>
                    </v-col>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div class="lable_size">
                                <label>Care Instructions</label>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="care_btn_container">
                                <md-button class="view_care_instructions md-raised" @click="AllCareInstructions">View Care Instructions</md-button>
                            </div>
                        </v-col>
                        <v-row>
                            <v-col cols="12" no-gutters>
                                <v-select
                                    class="select-care-instructions"
                                    v-model="model.selectedCareInstruction"
                                    :items="getAllCareInstructions"
                                    :item-value="`id`"
                                    multiple
                                    :no-data-text="noDataText"
                                >
                                    <template v-slot:item="{item}">
                                        <span v-if="item.icon"><img class="care-instruction-img" :src="item.icon"/></span>
                                        <span :class="item.icon && item.icon != null ? 'ml-4' : 'ml-0'">{{item.care_instruction_name}}</span>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <v-chip close @click:close="removeCareInstruction(item)">
                                            <span v-if="item.icon"><img class="care-instruction-img" :src="item.icon"/></span>
                                            <span>{{item.care_instruction_name}}</span>
                                        </v-chip>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-col cols="12">
                        <div class="lable_size">
                            <label>Sustainability, Circularity & Repairs</label>
                        </div>
                        <div>
                            <v-textarea
                                class="text-field"
                                v-model="model.sustainability"
                                maxlength="10000"
                                outlined
                                dense
                            >
                            </v-textarea>
                        </div>
                        <hr/>
                    </v-col>
                </v-row>
                <md-card-actions>
                    <md-button
                        v-if="!isPublished && !needTraceApproval"
                        :disabled="submitDisabled"
                        id="saveButton"
                        class="md-raised"
                        :style="`background-color: ${!submitDisabled ? '#582963': '#cccccc'}; color: ${!submitDisabled ? '#EEF0FB' : '#666666'};`"
                        @click="submit('submit');">{{submitDisabled ? "Submitting.." : "Submit"}}
                    </md-button>
                    <md-button
                        v-else
                        id="submitChanges"
                        :disabled="submitDisabled"
                        class="md-raised"
                        :style="`background-color: ${!submitDisabled ? '#582963': '#cccccc'}; color: ${!submitDisabled ? '#EEF0FB' : '#666666'};`"
                        @click="submit('update');">{{submitDisabled ? "Submitting.." : "Submit Changes"}}
                    </md-button>
                </md-card-actions>
            </v-form>
        </div>
        <div class="popup-container">
            <v-dialog persistent v-model="active" width="80%">
                <v-card width="100%">
                    <NonConsumerCertificationsHandleForm :active="active" @close="active=false"/>
                </v-card>
            </v-dialog>
        </div>
        <div>

            <v-dialog persistent v-model="showCareInstructions" width="80%">
                <v-card width="100%">
                    <NonConsumerCareInstructionHandleForm @update-instructions="updateCareInstructions" :showCareInstructions="showCareInstructions" @close="showCareInstructions=false"/>
                </v-card>
            </v-dialog>
        </div>
        <div v-if="isPublished" class="grey lighten-5 section" style="padding: 20px; margin-top: 20px;">
            <div
                class="grey lighten-5 section feedback-container"
                :style="!isSmallScreen ? 'padding: 20px;' : 'padding: 10px;'">
                <div class="more-info-container">
                    <div v-if="!isSmallScreen" class="icon-container">
                        <ellipse-icon
                            :width="'59px'"
                            :height="'59px'"
                            border="2px"
                            variant="success"
                        >
                            <customer-feedback-icon />
                        </ellipse-icon>
                        <span class="more-info-title title-underline">Customer Feedback</span>
                    </div>
                    <div v-else class="icon-container title-underline">
                        <customer-feedback-icon fill="#70D0CE" :width="24" :height="24"/>
                        <span class="more-info-title">Customer Feedback</span>
                    </div>
                    <review
                        :isMobile="isSmallScreen"
                        :styleNumber="styleNumber"
                        :inquiryId="$route.params.id"
                        :isAddReviewVisible="false"
                        :isAuthorizedToChangeReviewStatus="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import countryList from "country-list";
import debounce from "lodash/debounce";
import restAdapter from "../../restAdapter";
import notification from '../../notification';
import NonConsumerCertificationsHandleForm from "../Inquiry/NonConsumerCertificationsHandleForm.vue";
import NonConsumerCareInstructionHandleForm from "../Inquiry/NonConsumerCareInstructionHandleForm.vue";
import QrCode from './QrCode.vue';
import Review from './Review.vue';
import { setLeaveAlert, resetLeaveAlert } from '../../utils/confirmBox';

export default {
    name: "NonConsumerTraceForm",
    components: {
        NonConsumerCertificationsHandleForm,
        NonConsumerCareInstructionHandleForm,
        QrCode,
        Review
    },
    props: {
        traceLayout: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        replace: "United Kingdom of Great Britain and Northern Ireland",
        replaceBy: "United Kingdom",
        loading: true,
        copied: false,
        origin: '',
        submitDisabled: false,
        reviewBoxClose: false,
        rejectionComment: null,
        model: {
            itemDetails: [],
            productName: "",
            fabricComposition:"",
            selectedCareInstruction:[],
            sustainability:"",
        },
        ignoreFormEdit: false,
        supplierStatuses : [],
        deliveryMethods : [],
        styleNumber: null,
        isPublished: false,
        needTraceApproval: false,
        countries: [],
        arr: [],
        loadingS: null,
        viewAll: false,
        matchingCertifications:[],
        searchingCertifications : false,
        certificationStatus : false,
        active : false,
        showCareInstructions: false,
        allCareInstructions:[],
        careInstructions:[],
        buyerLogo:false,
        noDataText:"please wait",
        buyerLogoImg:null,
        productNameError: false,
        publicLink: "",
        qr: "",
        trackingCode: "",
        buyerLink: "",
        qrDialog:false,
        windowSize: window.innerWidth,
    }),

    filters: {
        truncate: function(text, stop, clamp) {
            return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },
  watch:{
    model: {
      handler: function(){
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    }
  },

  created(){
    window.addEventListener("resize", this.screenSizeHandler);
  },

  destroyed() {
    window.removeEventListener("resize", this.screenSizeHandler);
  },

  computed: {
    isSmallScreen() {
        return this.windowSize <= 750;
    },
    getAllCareInstructions(){
        return this.allCareInstructions.filter(item =>
            this.careInstructions.some(instruction => item.id == instruction.id) ||
            this.model.selectedCareInstruction.some(selected => item.id == selected)
        )
    },
  },

    mounted(){
        this.initialize ();
        this.origin = window.location.origin;
        this.readNonConsumerData();
        this.countries = countryList.getNames();
        const obj = Object.entries(this.countries);

        for (let i = 0; i < obj.length; i++) {
            this.arr.push({ id: obj[i][0], country: obj[i][1] === this.replace ? this.replaceBy : obj[i][1]})
        }
  },

    methods: {
        typingProductName(){
            if (this.model.productName && this.model.productName.length > 255) {
                this.productNameError = "Product name should be less than 255 characters";
            } else if (this.model.productName) {
                this.productNameError = false;
            } else { 
                this.productNameError = "Product name is required";
            }
        },
        screenSizeHandler(e) {
            this.windowSize = window.innerWidth;
            return this.windowSize;
        },
        removeCareInstruction(item) {
            this.model.selectedCareInstruction = this.model.selectedCareInstruction.filter(instruction=> instruction != item.id);
        },
        initialize () {
        restAdapter
          .get(`/api/trace/care_instructions/all_care_instructions`)
                .then(response => {
                    this.loading = false;
                    this.allCareInstructions = response.data.careInstructions;
                    this.careInstructions = response.data.careInstructions.filter(item=>item.is_showing);

                    if(!this.careInstructions.length){
                        this.noDataText = 'No care instructions found'
                    }
            }).catch(error => {
                    console.log(error);
            });
      },
        validateCountry(fieldId) {
            const field = document.getElementById(`field-${fieldId}`);
            field.innerHTML = '';
        },
        readNonConsumerData(){
            this.loading = true;
            restAdapter
                .get('/api/inquiry/' + this.$route.params.id + '/trace')
                .then(response => {
                    this.model.productName = response.data.product_name;
                    this.styleNumber = response.data.style_number;
                    this.isPublished = response.data.is_published;
                    this.needTraceApproval = response.data.need_trace_approval;
                    this.model.itemDetails = response.data.fields.map(element => {return {...element, searchQuery: ""}});
                    this.supplierStatuses = response.data.supplier_statuses;
                    this.deliveryMethods = response.data.delivery_methods;
                    this.model.fabricComposition = response.data.fabric_composition;
                    this.model.selectedCareInstruction = response.data.care_instructions ?? [];
                    this.model.sustainability = response.data.sustainability_and_circularity;
                    this.buyerLogoImg = response.data.buyer_logo;
                    this.buyerLink = response.data.buyer_link;
                    this.rejectionComment = response.data.rejection_comment;
                    this.getPublicData();
                    this.loading = false;
                    this.ignoreFormEdit = true;
                    resetLeaveAlert();
                })
                .catch(error => {
                    this.loading = false;
                    resetLeaveAlert();
                });
        },

        updateCareInstructions(value){
            this.careInstructions = value;
        },

        getPublicData() {
            const defaultPublicUrl = `${this.origin}/#/trace/${this.styleNumber}`
            this.publicLink = defaultPublicUrl;
            restAdapter.post('/api/trace/generate_qr', {
                publicUrl: defaultPublicUrl,
                inquiryId: this.$route.params.id
            }).then(response => {
                this.qr = response.data.qr;
                this.trackingCode = response.data.tracking_code;
            })
        },
        addUserTypeCertification (query, id) {
            this.loadingS = id;
            if(!query){
                this.loadingS = null;
                return null;
            }


            const value = this.model.itemDetails.find(element=>element.id === id);

            const certificationValue = value.details.certifications.find(element=>element.display_name === query);

            restAdapter.post(`/api/trace/certification/add`, {name: query})
                .then(response => {
                    this.addCertification(response.data, certificationValue, id);
                })
                .catch(error => {
                    this.loadingS = null;
                    notification.error(error.response.data.error);
                });
        },

        addListCertification (certification, id) {
            const value = this.model.itemDetails.find(element=>element.id === id);
            const certificationValue = value.details.certifications.find(element=>element.id === certification.id);
            this.addCertification(certification, certificationValue, id);
        },

        removeDbCertification (certification,id) {
            if (confirm("Remove this certification ?")) {
                restAdapter.post(`/api/trace/certification/remove/${certification.id}`)
                .then(response => {
                    this.model.itemDetails.searchQuery = "";
                    this.removeCertification(id,certification.id);
                })
                .catch(error => {
                    this.loadingS = null;
                    notification.error(error.response.data.error);
                });
            }
        },

        debounceCertifications: debounce(async function (query) {
            this.viewAll = false;
            restAdapter.get(`/api/trace/certifications/${query}`)
                .then(response => {
                if(response.data.certifications.length < 5){
                    this.viewAll = true;
                }
                this.matchingCertifications = response.data.certifications.slice(0, 4);
                this.searchingCertifications = false;
            });
        }, 500),

        async searchCertifications(query) {
            if (query) {
                this.searchingCertifications = true;
                await this.debounceCertifications(query);
            } else {
                this.matchingCertifications = [];

            }
        },

        async addCertification(data, certificationValue, id) {
            this.model.itemDetails = this.model.itemDetails.map(element => {
                if(element.id === id){
                return {...element, searchQuery: "", details: {...element.details, certifications: !certificationValue ? [...element.details.certifications, {...data, verified:false}] : [...element.details.certifications]}}
                }

                return {...element};
            })
            this.loadingS = null;
        },
        updateStatus(itemId,certId,status){
        this.model.itemDetails = this.model.itemDetails.map(item=>{
            if(item.id === itemId){
            return {...item, details:{...item.details, certifications: item.details.certifications.map(certification=>{
                    if(certification.id === certId){
                    return {...certification,verified: status };
                    }
                    return {...certification};
                })} }
            }
            return {...item};
        })
        },

        removeCertification(itemId, certId){
            this.model.itemDetails = this.model.itemDetails.map(element => {
                if(element.id === itemId){
                    return {
                        ...element,
                        searchQuery: "",
                        details: {
                            ...element.details,
                            certifications: element.details.certifications.filter(cert => cert.id !== certId)
                        }
                    }
                }
                return {...element}
            })
        },

        previewBuyerLogo(){
            if(this.buyerLogo) {
                this.buyerLogoImg = URL.createObjectURL(this.buyerLogo);
            }
            else{
                this.buyerLogoImg = null;
            }
        },

        submit(param){
            if(!this.model.productName || this.model.productName.length > 255){
                this.productNameError = !this.model.productName ? 'Product name is required' : 'Product name should be less than 255 characters';
                const field = document.getElementById(`product-name-field`);
                field.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
                return;
            }
            this.productNameError = false;
            this.submitDisabled = true;
            const toSubmit = this.formatDataForSubmission()
            if(toSubmit.error){
              this.submitDisabled = false;
              const toScroll = document.getElementById(`employees-ratio-field`);
              toScroll.scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
              });
              notification.error("Invalid Employees Ratio");
              return;
            }
            restAdapter
                .post(`/api/inquiry/${this.$route.params.id}/trace/${param}`, toSubmit)
                .then(response => {
                    notification.success("Sent for buyer approval");
                    this.rejectionComment = "";
                    this.needTraceApproval = true;
                    this.submitDisabled = false;
                    resetLeaveAlert();
                })
                .catch(error => {
                if (error.response.status == 400 && !error.response.data.error_locations) {
                    notification.error(error.response.data.error);
                } else if (error.response.data.error_locations) {
                    error.response.data.error_locations.forEach(location => {
                    const field = document.getElementById(`field-${location.trace_id}`);
                    field.innerHTML = error.response.data.message;
                    field.scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center'
                    });
                    })
                } else {
                    notification.error("Something went wrong, please try again later.");
                }
                this.submitDisabled = false;

            });
        },

        addBuyerLogo(){
            let answer = true;
            if(this.isPublished){
                answer = window.confirm(
                    "This action will directly update the public view."
                );
            }

            if(!answer) return;

            if(!this.buyerLogo){
                this.buyerLogoImg = null;
            }
            const buyerLogo = new FormData();
            buyerLogo.append('icon',this.buyerLogo);
            this.buyerLink && buyerLogo.append('buyer_link',this.buyerLink);
                restAdapter
                    .post(`/api/inquiry/${this.$route.params.id}/trace/update_logo`, buyerLogo)
                    .then(response => {
                        notification.success(response.data.message);
                    })
                    .catch(error => {
                        if(error.response && error.response.data){
                            notification.error(error.response.data.message);
                        }
                        else{
                            notification.error("Something went wrong, please try again later.");
                        }
                        this.submitDisabled = false;
                });
        },
        formatDataForSubmission(){
            let error = false;
            const toSubmit = {
                productName: this.model.productName,
                data: [],
                fabricComposition: this.model.fabricComposition,
                careInstructions: this.model.selectedCareInstruction,
                sustainability: this.model.sustainability,
            };
            this.model.itemDetails.map(element => {
                if(element.details.number_of_employees){
                  const count = (element.details.number_of_males ? parseFloat(element.details.number_of_males) : 0)
                    + (element.details.number_of_females ? parseFloat(element.details.number_of_females) : 0 )
                    + (element.details.number_of_others ? parseFloat(element.details.number_of_others) : 0);
                  error =  error || (count && (count < 99.99 || count > 100) ); //0.01 error buffer
                }
                const data = {...element.details,
                    details : {...element.details, certifications : element.details.certifications.map(certification=>{
                        return {...certification};
                    })
                }}
                toSubmit.data.push(data);
            })
            if(error) return {error: error};
            return toSubmit;
        },
        AllCertifications(){
            this.active = true;
        },
        AllCareInstructions(){
            this.showCareInstructions = true;
        },
        copying(){
            navigator.clipboard.writeText(document.getElementById("toCopy").textContent).then(() => this.copied = true);
            setTimeout(() => {
                this.copied= !this.copied;
            }, 5000);
        },

        openQrCode(){
            this.qrDialog = true;
        },

        searchAll(query){
            this.viewAll = true;
            restAdapter.get(`/api/trace/certifications/${query}/all`)
                .then(response => {
                this.matchingCertifications = response.data.certifications;
                this.searchingCertifications = false;
            });
        }
    },
}
</script>


<style scoped>

.md-double-line {
  overflow-y: scroll;
  max-height: 150px !important;
  z-index: 999 !important;
}

.v-subheader{
  padding-top: 5px;
  height: 20px;
}

.search-result {
  position: absolute;
  margin-top: -25px;
  margin-left: 20px;
  z-index: 99;
  max-height: 300px;
  width: 28.5% !important;
  overflow-y: auto;

}

.main_container{
  width: 100%;
  height: 50%;
  padding : 20px;
}

.text-field{
  padding-top : 20px;
  padding-left : 20px;
  padding-right : 20px;
}
.logo_btn{
    border: 1px solid #492a67;
    margin-top: 22px;
    margin-left: -40px;
    height: 38px;
    color: #492a67;
}
.color_stroke::v-deep .md-progress-spinner-circle{
  stroke: #492a67 !important;
}

.lable_size{
    width: auto;
    margin-left: 10px;
    padding-top: 20px;
    text-align: left;
    font-size: 20px;
    color : #582963;
}

.radio_group_size{
  padding-left: 80px;
  padding-top: 15px;
}

.deliveryM_radio{
  padding-right: 50px;
}

.spinner-box {
  position: relative;
}

.mx-auto {
  margin-left: 10px;
  max-height: 45px;
  width: 90%;
  overflow-x: hidden ;
  overflow-y: auto;
}

.certifications_size{
  margin: 5px;
  color: #501D5F !important;
  border-color: #501D5F !important;
}

.certifications_font_style{
  font-size: 13px;
  color: #501D5F;
}

.description-container{
  text-align: left !important;
  max-width: 250px !important;
}

.style{
  background-color: #582963;
  width: 100% !important;
  height: 100% !important;
}

.add_button{
  min-width: 10px !important;
  margin: 0px 5px !important;
  border: 1px solid #c6a8ec;
}
.remove_button{
  min-width: 10px !important;
  margin: 0px 5px !important;
  border: 1px solid red;
}
.message-div {
  float: left;
}
.message-waiting {
  background-color: #70D0CE;
  color: white !important;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.message-done {
  background-color: #c6a8ec;
  color: white !important;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.message-rejected {
  background-color: lightcoral;
  color: white !important;
  align-items: center;
  display: flex;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.url-div{
  border: 0.5px solid;
  border-color: #501D5F !important;
  border-radius: 8px;
  padding: 6px;

}
.url-span {
  color : #C2AAE8;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.view_all {
  margin: auto;
  border: 1px solid #492a67;
  color: #492a67;
}

.bar_color::v-deep .md-progress-bar-track:after{
  background-color: #582963 !important;
}

.md-progress-bar.md-theme-default.md-indeterminate, .md-progress-bar.md-theme-default.md-query {
  background-color: #c6a8ec !important;
  background-color: var(--md-theme-default-primary-on-, #c6a8ec) !important;
}

.bar_color::v-deep .md-progress-bar-fill:after{
  background-color: #582963 !important;
}

.certifications_container{
  height: auto;
}

.certification_display_name{
  overflow-x:auto;
  margin-right:10px;
  margin-bottom: 5px;
}

.certification_display_name::-webkit-scrollbar{
  /* background-color: gray; */
  height: 8px;
}

.certification_display_name::-webkit-scrollbar-thumb {
  background-color: rgb(192, 190, 190);
}
.view_certifications{
  background-color: #501D5F !important;
  color: white !important;
  border-color:  #501D5F !important;
  border: 2px solid;
  border-radius: 8px;
  width: 200px;
  margin : 11px 12px 10px 5px;
}
.countryError {
  color: red;
}
.qr-code-comp {
    padding: 10px;
}
.qr-code-button {
    color: white !important;
    border: 2px solid;
    border-radius: 8px;
    margin : 11px 0px 10px 0px;
}

.care_btn_container{
    text-align: right;
    margin: 13px 15px 0 0px;
    font-size: 20px;
}

.view_care_instructions{
    background-color: #501D5F !important;
    color: white !important;
    border-color:  #501D5F !important;
    border: 2px solid;
    border-radius: 8px;
}

.select-care-instructions >>> .v-chip__content {
    font-size: 15px;
    font-weight: 500;
}

.care-instruction-img{
    width: 50px;
    margin-right: 5px;
}

.more-info-title {
    margin-left: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #473068;
}

.title-underline {
    border-bottom: 1px solid #70D0CE;
    width: fit-content;
}

.more-info-container {
    margin-top: 0px;
    text-align: left;
    min-height: 50px;
    width: 95%;
}

.feedback-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container {
    display:flex;
    align-items: center;
    margin-bottom: 15px;
}

.request-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

.request-item {
    text-align: left;
    margin: 0px 10px 10px 0px;
    padding: 10px;
    color: #473068;
    background: white;
    border-radius: 12px;
}

.buyer-request {
    background-color: #70D0CE;
}
</style>
