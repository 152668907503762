<template>
  <div class="container">
    <div v-if="styleListLoading" class="text-center loading-container mt-5">
      <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <div v-else class="table-container">
      <v-row  class=" table-row">
        <v-col cols="7">
          <table class="table-data">
            <thead>
              <tr>
                <th class="subtitle">Main Task</th>
                <th class="subtitle">Sub Task</th>
                <th class="subtitle">Approval</th>
              </tr>
            </thead>
            <tbody v-for="mainTask in styleTaskList" v-bind:key="mainTask.id">
              <tr>
                <td class="table-data main-task" :class="insideMainTask == mainTask.id ? 'table-data-clicked' : 'table-data'" :rowspan="subTaskList.filter(task => mainTask.id == task.style_file_task_id).length + 1">
                  {{mainTask.name}}
                </td>
              </tr>
              <tr v-for="subTask in subTaskList.filter(task => mainTask.id == task.style_file_task_id)" v-bind:key="subTask.id">
                <td
                  :class="subtaskClick == subTask.id
                  ? 'table-data-clicked'
                  : 'table-data'"
                  class="sub-task"
                  @click="subtaskClicked(subTask.style_file_task_id, subTask.id)"
                >
                  <span>{{subTask.name}}</span>
                </td>
                <td class="status table-data">
                  <v-checkbox
                    v-model="subTask.status"
                    @click="subtaskApproval(subTask.id, subTask.name)"
                    color="#47C0BE"
                    class="mr-0"
                    :disabled="currentUserUserType == 'supplier'"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col cols="5" class="second-col">
          <table class="table-data more-info-table">
            <thead>
              <tr>
                <th v-if="subtaskClick" class="subtitle-files">Files</th>
              </tr>
            </thead>
            <tbody>
              <tr style="height:96%;">
                <td class="table-data file-upload-container">
                  <StyleFilesContainer
                    :inquiryId="parseInt(inquiryId)"
                    :subTaskId="subtaskClick"
                    :subTaskData="subTaskListData.filter(data => subtaskClick == data.style_file_sub_task_id)"
                    @reload-data="getStyleFileData()"
                    @refresh-activity-log="getActivityLog()"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="!styleListLoading" class="mb-3 mt-5" style="padding: 1px 25px 0px 24px;">
      <v-col  class="activity-log-title-container" cols="12">
        <span class="activity-log-title">Activity Log</span>
        <v-icon v-if="!showActivityLog" @click="showActivityLog = true" class="ml-5 activity-log-title icon-class" large>expand_more</v-icon>
        <v-icon v-else @click="showActivityLog = false" class="ml-5 activity-log-title icon-class" large>expand_less</v-icon>
      </v-col>
    </v-row>
    <md-card class="container" v-if="showActivityLog">
      <form>
        <md-card-content class="search-content">
          <v-row class="inquiry-search-bar">
            <v-col cols="6">
              <v-text-field
                label="Search"
                v-on:input="debounceSearch"
                name="search"
                id="search"
                v-model="query"
                clearable
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="subTaskList"
                item-text="name"
                item-value="id"
                v-on:input="debounceSearch"
                data-cy="sub-task"
                name="sub-task"
                id="sub-task"
                v-model="sortSubTaskId"
                placeholder="Sub Task"
                clearable
              >
                <template
                 v-slot:item="{item}">
                  {{item.name}}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </md-card-content>
      </form>
      <md-card class="topics-container">
        <md-card-content>
          <v-row align="center" style="margin: -17px;">
            <v-col cols="5">
              <span class="activity-title">
                Activity
              </span>
            </v-col>
            <v-col cols="2">
              <span class="activity-title">
                Brand name
              </span>
            </v-col>
            <v-col cols="2">
              <span class="activity-title">
                Date
              </span>
            </v-col>
            <v-col cols="3">
              <span class="activity-title">
                Sub task
              </span>
            </v-col>
          </v-row>
        </md-card-content>
      </md-card>
      <div v-if="activityLogLoading" class="text-center loading-container">
        <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
      </div>
      <div v-else>
        <div v-if="!activityLog.length" class="empty-container text-center">
          <div class="empty-data"> No activities found</div>
        </div>
        <div v-else>
        <md-card
          v-for="notification in activityLog"
          v-bind:key="notification.id"
          class="notification-container"
        >
          <md-card-content>
            <v-row align="center" style="margin: -17px;">
              <v-col cols="5">
                <span class="notification-message">
                  {{ notification.message }}
                </span>
              </v-col>
              <v-col cols="2">
                <v-chip class="chip-style" color="#FFF2F0">
                  <span class="sender-name">
                    {{notification.creator.brand_name
                    ? notification.creator.brand_name
                    : notification.creator.first_name+" "+notification.creator.last_name
                    }}
                  </span>
                </v-chip>
              </v-col>
              <v-col cols="2" class="md-caption">
                <span class="date-style">
                  {{ new Date(notification.created_at).toLocaleString() }}
                </span>
              </v-col>
              <v-col cols="3">
                <v-chip class="chip-style" color="rgba(112, 208, 206, 0.18)">
                  <v-tooltip top color="#47c0be">
                      <template v-slot:activator="{ on, attrs }">
                        <div>
                          <span
                            v-bind="attrs"
                            v-on="on" class="sub-task-name"
                            >
                              {{ subTaskList.filter(task => notification.style_file_sub_task_id == task.id)[0].name | truncate(15, "...") }}
                          </span>
                        </div>
                      </template>
                      <div class="description-child-container">
                        <div>{{ subTaskList.filter(task => notification.style_file_sub_task_id == task.id)[0].name }}</div>
                      </div>
                  </v-tooltip>
                </v-chip>
              </v-col>
            </v-row>
          </md-card-content>
        </md-card>
        </div>
        <Pagination
          style="margin: -9px;"
          :current-page="currentPage"
          :total-count="totalCount"
          @load-page="loadPage"
          v-if="activityLog.length"
        />
      </div>
    </md-card>
  </div>
</template>

<script>

import restAdapter from "../../restAdapter";
import debounce from "lodash/debounce";
import Pagination from "../../components/Pagination";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import StyleFilesContainer from "../inquiry/StyleFilesContainer.vue"
import auth from "../../auth";
import { inquiryDataBus } from '../../utils/inquiryDataBus';

export default {
  name: "StyleFile",
  components:{
    Pagination,
    StyleFilesContainer,
  },

  data: ()=>({
    inquiryId: null,
    currentUserUserType:null,
    subtaskClick: 1,
    insideMainTask: 1,
    approve:[],
    query:"",
    sortSubTaskId: null,
    styleTaskList: [],
    subTaskList:[],
    activeTab: 'styleList',
    activityLog:[],
    styleListLoading: false,
    activityLogLoading: false,
    files:[{id:1}],
    subTaskListData:[],
    currentPage: 1,
    totalCount:0,
    showActivityLog:false,
  }),

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  created() {
    inquiryDataBus.$on('reload-style-file', this.reloadStyleFileData);
  },

  mounted(){
    this.initialize();
  },

  methods:{
    async initialize(){
      this.currentUserUserType = auth.user.userType;
      this.inquiryId = this.$route.params.id;
      await this.getApproveStatus();
      this.getStyleFileData();
      this.getStyleFileTasks();
      this.getStyleFileSubTasks();
      this.getActivityLog();
    },

    reloadStyleFileData(){
      this.getStyleFileData();
      this.getActivityLog();
    },

    subtaskClicked(taskId, subId){
      this.subtaskClick = subId;
      this.insideMainTask = taskId;
    },

    debounceSearch: debounce(function () {
      this.getActivityLog();
    }, 1000),

    getActivityLog() {
      this.activityLogLoading = true;
      let url = `/api/inquiries/${this.inquiryId}/style_file_log?page=` + this.currentPage;
      url += "&query=" + (this.query == null ?  "" : this.query);
      url += "&sub_task=" + (this.sortSubTaskId == null ?  "" : this.sortSubTaskId);

      restAdapter.get(url).then((response) => {
        this.activityLogLoading = false;
        this.activityLog = response.data.data;
        this.totalCount = response.data.meta.total;
      });
    },

    loadPage(page) {
      this.currentPage = page;
      this.getActivityLog();
    },

    getStyleFileData(){
      restAdapter.get(`/api/inquiries/${this.inquiryId}/style_files`).then((response) => {
        this.subTaskListData = response.data.style_file_data;
      });
    },

    getStyleFileTasks(){
      this.styleListLoading = true;
      restAdapter.get("/api/style_file/style_file_tasks").then((response) => {
        this.styleListLoading = false;
        this.styleTaskList = response.data.style_file_task_data;
      });
    },

    getStyleFileSubTasks(){
      this.styleListLoading = true;
      restAdapter.get("/api/style_file/style_file_sub_tasks").then((response) => {
        this.styleListLoading = false;
        this.subTaskList = response.data.style_file_sub_task_data;
        this.setApproveStatus();
      });
    },

    async getApproveStatus(){
      await restAdapter.get(`/api/inquiries/${this.inquiryId}/style_file_sub_task_status`).then((response) => {
        this.subTaskListApproveStatus = response.data.style_file_sub_task_statuses;
      });
    },

    setApproveStatus(){
      this.subTaskList = this.subTaskList.map((item) => {
        return {...item,
        status: this.subTaskListApproveStatus.find(task => item.style_file_sub_task_id == task.style_file_sub_task_id)
        ? this.subTaskListApproveStatus.find(task => item.style_file_sub_task_id == task.style_file_sub_task_id).approve_status : false}
      })
    },

    subtaskApproval(id, name){
      restAdapter.put(`/api/inquiries/${this.inquiryId}/approve_style_sub_task/${id}`).then(()=> {
        notification.success( name+" sub task status updated successfully");
      });
    },
  }
}
</script>

<style lang="scss" scoped>

.loading-container {
  height: 500px;
}

.color_stroke::v-deep .md-progress-spinner-circle{
  stroke: #492a67 !important;
}

.container {
  background-color: #eef1fa;
  padding: 10px;
  width: 100%;
  box-shadow: unset;
}

.empty-container{
  min-height: 380px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.empty-data{
  font-weight: 600;
  font-size: 16px;
  color: #473068;
}

/* .activity-log-container{
  background-color: #eef1fa;
  padding: 10px;
  width: 100%;
  margin: 0px 20%;
}

.theme--light.v-tabs-items {
    background-color: #eef1fa;
} */

table{
  width: 100%;
  height: 100%;
  /* border-collapse: collapse; */
}

table td{
  text-align: left;
  border: 0.5px solid #473068;
  color: #442D65;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 14px;
}

td {
  height: 70px;
}

th{
  border: 0.5px solid #473068;
  text-align: center;
  height: 50px;
  background-color: #47C0BE;
  color: #442D65;
  font-weight: 600;
  font-size: 16px;
}

.subtitle{
  padding: 0px 15px;
}

.subtitle-files{
  background-color: #473068;
  color: #FFFFFF;
}

.table-data{
  background-color: #FFFFFF;
  height: auto;
}

.file-upload-container{
  padding-top: 15px;
}

.table-data-clicked{
  background-color:#E5F7F6;
  height: 50px;
}

.status{
  justify-content: center;
  display: flex;
  border: 1px solid #473068;
  padding: 3px 1px 0px 10px;
  height: 50px;
  align-items: center;
  /* padding: 3px 1px 0px 30px; */
}

.main-task{
  width: 40%;
  vertical-align: top;
  padding-top: 25px;
}

.sub-task{
  width: 50%;
  height: 50px;
  cursor: pointer;
}

.more-info-table{
  height: 100%;
}


.second-col{
  margin-left: -25.8px;
}

.md-card-content{
  padding: 25px;
  text-align: left;
  color: #473068;

}

.notification-container{
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  margin-bottom: 20px;
  min-height: 70px;
}

.topics-container{
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  margin-bottom: 20px;
  background-color: #47C0BE;
}

.sender-name{
  color:#EA7A66;
  font-size:15px;
}

.notification-message{
  font-weight: 600;
  font-size: 16px;
}

.activity-title{
  font-weight: 600;
  font-size: 16px;
}

.sub-task-name{
  color:#47C0BE;
  font-size:15px;
  margin: 0px 10px;
}

.date-style{
  font-weight: 500;
  color: #473068;
  font-size:15px;
}

.chip-style{
  min-width: 70%;
  justify-content: center;
  height: 35px;
}

.search-content{
    margin-bottom: 40px;
    padding: 12px;
}

.inquiry-search-bar {
  text-align: right;
  align-items: center;
  justify-content: left;
  background:#ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  height: 75px;
}

.description-child-container{
  text-align: left !important;
  max-width: 250px !important;
}

.activity-log-title{
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
}

.activity-log-title-container{
  background-color: #473068;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-class{
  background-color: rgb(255 255 255 / 12%);
  border-radius: 29px;
}

.table-row{
  justify-content: center;
  margin-top:5px;
  margin-bottom:5px;
}

@media only screen and (max-width: 900px) {
  .table-row{
    width: 1000px;
  }
  .table-container{
    overflow-x: scroll;
  }
  .status{
    height: auto;
  }
}

</style>
