<template>
    <div> 
        <div class="close" @click="closeWindowclose">
            <popup-close 
            style="float: right;" 
            />
        </div>
        <div v-if="loading" class="text-center" style="height: 500px;">
                <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div v-else class="certification-container">
            <h1 style="font-family: 'TrebuchetMS', sans-serif;">Certifications</h1>
            <div class="alignment">
                <v-data-table
                    class="text-color"
                    :headers="headers"
                    :items="certifications"
                    :search="search"
                    :loading="loadingTable"
                    :no-data-text="noDataText"
                    :items-per-page="itemsPerPage"
                    :hide-default-footer="true"
                    :page.sync="page"
                    :scrollable="false"
                    @pagination="paginated"
                    style="color:#492a67"
                >
                    <template v-slot:[`item.id`]="{ item }">
                        <td class="tdata-noborder text">{{certifications.indexOf(item)+1}}</td>
                    </template>

                    <template  v-slot:[`item.description`]="{ item }">
                            <td v-if="item.description" class="tdata-noborder">{{item.description}}</td>
                            <td v-else style="color: rgba(0, 0, 0, 0.38);" class="tdata-noborder">no description provided...!</td>
                    </template>
                    
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        ><v-text-field
                            class="search-field"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            style="width:5px !important;"
                            
                        >
                        </v-text-field>
                            <v-spacer></v-spacer>
                            <v-dialog
                                v-model="addDialog"
                                max-width="500px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="mb-2 new-certification-btn"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        New Certification
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h4">Add New Certification</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field 
                                                        v-model="addingItem.display_name"
                                                        label="Certification name"
                                                        counter
                                                        maxlength="750"
                                                        :rules="[rules.required, rules.counterName]"
                                                        style="color:#492a67;"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field
                                                        class="text"
                                                        v-model="addingItem.description"
                                                        label="Description"
                                                        counter
                                                        maxlength="7500"
                                                        :rules="[rules.counterDesc]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="close('add')"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="addNewCertification"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-dialog
                                v-model="editDialog"
                                max-width="500px"
                            >
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h4">Edit Certification</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field
                                                        v-model="editedItem.display_name"
                                                        class="text"
                                                        label="Certification name"
                                                        counter
                                                        maxlength="750"
                                                        :rules="[rules.required, rules.counterName]"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    style="color:red;"
                                                >
                                                    <v-text-field
                                                        v-model="editedItem.description"
                                                        label="Description"
                                                        counter
                                                        maxlength="7500"
                                                        :rules="[rules.counterDesc]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="close('edit')"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="editCertification"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="purple darken-1" text @click="closeDelete">Cancel</v-btn>
                                        <v-btn color="purple darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      
                        <div class="icon-cont">
                            <div class="action-button" @click="editItem(item)">
                                <edit-inquiry width="17" height="18" />
                            </div>
                            <div class="action-button" @click="deleteItem(item)">
                                <delete-icon width="17" height="18" />
                            </div>
                        </div>
                        <div style="color: blue;"> <!-- define the color style -->
                            {{ item.actions }}
                        </div>
                    </template>
                </v-data-table>
            </div>
            <div style="position: relative; min-height: 70px; height: 100%; width: 100%;">
                <div style="position: absolute; bottom: 0; right: 0;">
                    <pagination
                        :currentPage="page"
                        :totalCount="itemCount"
                        @load-page="loadPage"
                        :perPageCount="itemsPerPage"
                        v-if="itemCount"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PopupClose from "../../assets/icons/PopupClose.vue"
import restAdapter from "../../restAdapter";
import notification from '../../notification';
import Pagination from '../Pagination.vue';



export default {
    components: { Pagination },
    name:"NonConsumerCertificationsHandleForm",

    components: {
        PopupClose,
        Pagination,
    },
    props:{
        active:{
            type:Boolean,
        } 
    },
    data: ()=>({
        page: 1,
        itemCount: 0,
        itemsPerPage: 5,
        search: '',
        searchingCertifications : false,
        loading: true,
        loadingTable: false,
        editDialog: false,
        addDialog: false,
        dialogDelete: false,
        noDataText:' Please wait...',
        headers: [
            { text: 'Number', value: 'id', align: 'start',  sortable: false ,filterable:false, width: '20%' },
            { text: 'Certification Name', value: 'display_name', align: 'start', sortable: false, width: '30%' },
            { text: 'Description', value: 'description', align: 'start', sortable: false, width: '30%' },
            { text: 'Actions', value: 'actions', align: 'center', sortable: false ,width: '20%' },
        ],
        certifications:[],
        editedIndex: -1,
        editItemId: null,
        editedItem: {},
        addingItem:{},
        defaultItem: {},
        deleteItemId: null,
        rules: {
          required: value => !!value || 'Required.',
          counterName: value => value.length <= 750 || 'Max 750 characters',
          counterDesc: value => value.length <= 7500 || 'Max 7500 characters',
        }
    }),

    watch: {
        active: {
            handler: function(){
                if(this.active){
                    this.initialize();
                }
            } 
        }
    },

    mounted(){
        this.initialize ();
    },

    methods: {
      closeWindowclose(){
        this.$emit('close');
      },
      
      loadPage(page){
        this.page = page;
      },

      paginated(pagination){
        this.itemCount = pagination.itemsLength;
      },

      initialize () {
        restAdapter
          .get(`/api/trace/certifications/all_certifications`)
                .then(response => {
                    this.loading = false;
                    this.certifications = response.data.certifications;
                    if(!this.certifications.length){
                        this.noDataText = 'No certifications found'
                    }
            }).catch(error => {
                    console.log(error);
            });
      },

      editItem (item) {
        this.editedIndex = this.certifications.indexOf(item);
        this.editItemId = item.id;
        this.editedItem = Object.assign({}, item);
        this.editDialog = true;
      },

      editCertification(){
        this.loadingTable = true;
        restAdapter.post(`/api/trace/certification/update/${this.editItemId}`,{name: this.editedItem.display_name , description: this.editedItem.description})
          .then(response => {
            this.loadingTable = false;
            this.certifications = this.certifications.map(certification =>{
                if(certification.id === this.editItemId){
                    return {...certification, display_name: this.editedItem.display_name, description: this.editedItem.description}
                }
                return {...certification}
            })
            this.editDialog = false;
            notification.success("Certification Updated successfully");
          })
          .catch(error => {
              notification.error(error.response.data.error);
              this.loadingTable = false;
          });
      },

      deleteItem (item) {
        this.editedIndex = this.certifications.indexOf(item);
        this.deleteItemId = item.id;
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;       
      },

      deleteItemConfirm () {
        this.certifications.splice(this.editedIndex, 1);
        restAdapter.post(`/api/trace/certification/remove/${this.deleteItemId}`)
          .then(response => {
            notification.success("Certification deleted successfully");
          })
          .catch(error => {
              notification.error(error.response.data.error);
          });
        this.closeDelete() 
      },

      close (status) {
        if(status === 'add'){
            this.addDialog = false;
            this.editedIndex = -1;
        }
        else{
            this.editDialog = false;
            this.editedIndex = -1;
        }
      },

      closeDelete () {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
      },

      addNewCertification () {
        this.loadingTable = true;
        restAdapter.post(`/api/trace/certification/add`, {name: this.addingItem.display_name , description: this.addingItem.description})
          .then(response => {
                this.loadingTable = false;
                this.addingItem = {};
                if(!response.data.already_added_status){
                    this.certifications.push({id: response.data.id, display_name: response.data.display_name, description: response.data.description});
                    notification.success("New Certification Added Successfully");
                }
                else{
                    this.addDialog = false;
                    notification.error("This Certification Already Exist");
                }
          })
          .catch(error => {
                notification.error(error.response.data.error);
                this.loadingTable = false;
          });
          this.addingItem = {};
      },
    },
}
</script>

<style>

.certification-container{
    padding: 0px 30px 30px 30px;
    left: 370px;
    top: 353px;
    background-color: #FFFFFF;
    border-radius: 7px;
    margin: auto;
    color:#492a67
}
.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #492a67 !important;
}

.search {
    padding: 2px 5px 2px 5px; 
    margin-right: 5px;
    text-align: right;
    width: 289px;
}

.new-certification-btn{
    background-color: #492a67 !important;
    color: white !important;
    border-color:  #492a67 !important;
    border-style: solid;
}

.v-data-table td {
    padding: 0px 0px;
}

.v-toolbar__content, .v-toolbar__extension {
    padding: 0px 0px;
}
.tdata-noborder{
    border-right : 0px solid !important;
    border-left : 0px solid !important;
    border-color:#492a67;
}
.text-center1{
    margin: -10px 0px;
    border-radius: 3px;
    display: flex;
    justify-content: flex-end;
}
.page {
    border-radius: 3px;
}
.alignment{
    position: relative;
    color:#492a67;
}
.text{
    color:#492a67;
}
.label {
  color:#492a67;
}
.v-data-table-header{
    color:#492a67;
}

.text-color > .v-data-table__wrapper > table > thead > tr:last-child > th {
    color:#492a67;
    border-color:#492a67;
}
.theme--light.text-color > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row){
    border-color: #492a67;
}
.theme--light.text-color > .v-data-table__wrapper > table > tbody > tr:last-child > td{
    border-bottom: thin solid #492a67;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #492a67;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-color: #492a67;
}
.v-icon.v-icon.v-icon--link {
    color: #492a67;
}
.search-field .v-icon.v-icon {
    color:#492a67;
}
.icon-cont {
    display: flex;
    justify-content:center;
}
.action-button {
    cursor: pointer;
    margin: 8px;
}
.close-icon {
    float:right;
    border-radius: 50%;
    box-shadow: 0 0 2px #333;
    cursor: pointer;
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 2.5rem;
    font-size: 28px;
    color:#492a67;
}
.close {
    margin: 0px 18px 18px 18px;
    padding-top: 18px;
    width: auto;
    cursor: pointer;
  }
</style>
