import { render, staticRenderFns } from "./StyleFile.vue?vue&type=template&id=1ca45770&scoped=true&"
import script from "./StyleFile.vue?vue&type=script&lang=js&"
export * from "./StyleFile.vue?vue&type=script&lang=js&"
import style0 from "./StyleFile.vue?vue&type=style&index=0&id=1ca45770&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca45770",
  null
  
)

export default component.exports