<template>
  <v-container id="supplier-assignment" fluid tag="section">
    <div v-if="saving" class="spinner-box">
      <md-progress-spinner class="color-stroke" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <v-row>
      <v-col cols="12">
        <div style="text-align:left;">
          <div class="back_btn_class text_color" @click="back_to_inquiry"><v-icon class="arrow_icon">chevron_left</v-icon><span class="mr-1">Back to Style</span></div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="mt-1">
        <!-- <div class="subtitle-4 font-weight-bold mb-5">
          Assign / Unassign suppliers
        </div> -->
        <v-container class="py-0" v-if="!loading">
          <v-row>
            <v-col cols="12" lg="6">
              <div class="md-subheading subheading_text">Style Details</div>
              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Style Number :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ model.styleNumber }}</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Product Type :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ getProductTypeName(model.productType) }}</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Category :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ getCategoryName(model.category) }}</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Quantity :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ model.quantity }}</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Number Of Colours :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ model.noOfColors }}</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Material :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ getMaterialName(model.material) }}</label>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Services Required :</label>
                </v-col>
                <v-col cols="6" class="form-value"></v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="form-label">
                  <label>Currency :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ getCurrencyName(model.currency) }}</label>
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-col cols="6" class="form-label">
                  <label>Additional Information :</label>
                </v-col>
                <v-col cols="6" class="form-value">
                  <label>{{ model.additionalInformation }}</label>
                </v-col>
              </v-row>

              <md-divider class="mb-5" />
              <div class="md-subheading assigned_suppliers_subheading_text">Assigned Suppliers</div>
              <div v-if="assignedSuppliers.length > 0">
                <v-row
                  v-for="supplier in assignedSuppliers"
                  :key="supplier.supplier_id"
                >
                  <v-col cols="6" class="form-label">
                    <div>
                      <span class="supplier-name">{{ supplier.first_name + " " + supplier.last_name }}</span>
                      <!-- <span class="md-subhead">{{ supplier.brand_name }}</span> -->
                    </div>
                  </v-col>
                  <v-col cols="6" class="form-value">
                    <ui-button
                      small
                      className="danger"
                      title="Unassign"
                      class="unassign_btn_class"
                      data-cy="supplier_assignment__btn-unassign"
                      @click="unassign(supplier)"
                      :disabled="saving"
                    >
                      <v-icon dark class="mr-1">mdi-account-cancel</v-icon>
                      </ui-button>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="6" class="form-label"></v-col>
                  <v-col cols="6" class="form-value">
                    <label>No suppliers assigned yet.</label>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <form
                novalidate
                @submit.prevent="getSuppliers"
                class="supplier-form"
              >
                <div class="supplier-search-bar">
                    <md-field class="field_size">
                      <label class="color_class" for="search-query">Search</label>
                      <md-input
                        name="search"
                        id="search"
                        v-model="query"
                        :disabled="loading"
                      />
                    </md-field>
                    <md-field class="field_size">
                      <label class="color_class" for="supplier_roles">Supplier Roles</label>
                      <md-select
                        v-model="selectedSupplierRoles"
                        name="supplier_roles"
                        id="supplier-roles"
                        multiple
                      >
                      <div class="supplier_roles_container">
                        <md-option
                          class="list_color"
                          v-for="supplierRole in supplierRoles"
                          :key="'supplierRole-' + supplierRole.id"
                          :value="supplierRole.id"
                          >{{ supplierRole.display_name }}</md-option
                        ></div>
                      </md-select>
                    </md-field>
                    <ui-button
                      class="mt-4 search_class"
                      title="Search"
                      outlined
                      type="submit"
                      :disabled="loading"
                    >
                    </ui-button>
                </div>
              </form>
              <v-row class="supplier-list" v-if="suppliers.length > 0">
                <v-col
                  cols="12"
                  v-for="supplier in suppliers"
                  :key="supplier.id"
                >
                  <div class="md-layout-item supplier-list__item">
                    <md-card class="card-style">
                      <div
                        class="md-layout-item md-size-35 md-xsmall-size-100 ml-3"
                      >
                        <span class="supplier-name">
                          {{ supplier.first_name + " " + supplier.last_name }}
                        </span>
                      </div>
                      <div
                        class="md-layout-item md-size-30 md-xsmall-size-100"
                      >
                        <span class="supplier-brand">
                          <md-chip class="supplier-brand-name"> {{ supplier.brand_name }}</md-chip>
                        </span>
                      </div>
                      <div>
                        <ui-button
                          small
                          className="success-full"
                          class="btn_class"
                          title="Assign"
                          v-if="!isAssignedSupplier(supplier.id)"
                          @click="assign(supplier)"
                          :disabled="saving"
                        >
                          <v-icon dark class="mr-1">how_to_reg</v-icon>
                        </ui-button>
                        <ui-button
                          small
                          className="danger"
                          class="btn_class"
                          title="Unassign"
                          data-cy="supplier_assignment__btn-unassign"
                          v-else
                          @click="unassign(supplier)"
                          :disabled="saving"
                        >
                          <v-icon dark class="mr-1">mdi-account-cancel</v-icon>
                        </ui-button>
                      </div>
                    </md-card>
                  </div>
                </v-col>
                <v-row class="ml-2">
                  <v-col cols="12">
                    <div class="pagination-style">
                      <Pagination
                        :current-page="currentPage"
                        :total-count="totalCount"
                        @load-page="loadPage"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-row>
              <v-row v-else>
                <v-col cols="12">No suppliers found.</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="loading" class="my-5">
          <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import auth from "../../auth";
import Pagination from "../../components/Pagination";

export default {
  name: "supplierAssignment",
  components: {
    Pagination
  },
  data: () => ({
    model: {
      id: null,
      styleNumber: null,
      productType: null,
      category: [],
      quantity: null,
      noOfColors: null,
      material: null,
      selectedServices: [],
      serviceDeadlines: {},
      currency: null,
      additionalInformation: null,
      files: [],
    },
    productTypes: [],
    materials: [],
    currencies: [],
    services: [],
    categories: [],
    supplierRoles: [],
    saving: false,
    loading: false,
    suppliers: [],
    assignedSuppliers: [],
    query: "",
    selectedSupplierRoles: [],
    currentPage: 1,
    totalCount: 0,
  }),
  mounted() {
    const inquiryId = this.$route.params.id;

    this.getInquiry(inquiryId).then((response) => {
      const inquiry = response.data.inquiry;

      this.model.id = inquiry.id;
      this.model.styleNumber = inquiry.style_number;
      this.model.productType = inquiry.product_type;
      this.model.category = inquiry.categories;
      this.model.quantity = inquiry.bulk_order_quantity;
      this.model.noOfColors = inquiry.colour_number;
      this.model.material = inquiry.materials;
      this.model.currency = inquiry.currency;
      this.model.additionalInformation = inquiry.additional_information;
      this.model.createdBy = inquiry.created_by;
      this.model.accountManagerId = inquiry.account_manager_id;

      // TODO: service deadlines are not retreive correclty

      this.assignedSuppliers = [];
      response.data.suppliers.forEach((supplier) => {
        supplier.id = supplier.supplier_id;
        this.assignedSuppliers.push(supplier);
      });
    });

    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
    });
    this.getMaterials().then((response) => {
      this.materials = response.data.materials;
    });
    this.getCurrencies().then((response) => {
      this.currencies = response.data.currencies;
    });
    this.getServices().then((response) => {
      this.services = response.data.services;
    });
    this.getCategories().then((response) => {
      this.categories = response.data.categories;
    });
    this.getSupplierRoles().then((response) => {
      this.supplierRoles = response.data.roles;
    });

    this.getSuppliers();
  },
  methods: {
    back_to_inquiry(){
      this.$emit("show-options", 1);
    },
    getSuppliers() {
      this.loading = true;
      let url = "/api/suppliers?page=" + this.currentPage;
      if (this.query) {
        url += "&query=" + this.query;
      }
      if (this.selectedSupplierRoles.length > 0) {
        url += "&supplier_role_ids=" + this.selectedSupplierRoles.join();
      }

      restAdapter.get(url).then((response) => {
        this.suppliers = response.data.data;
        this.totalCount = response.data.meta.total;
        this.loading = false;
      });
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    getMaterials() {
      return restAdapter.get("/api/inquire_materials");
    },
    getCurrencies() {
      return restAdapter.get("/api/inquire_currencies");
    },
    getServices() {
      return restAdapter.get("/api/inquire_services");
    },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    getSupplierRoles() {
      return restAdapter.get("/api/login/display_supplier_role");
    },
    getProductTypeName(id) {
      let name = "";
      this.productTypes.forEach((productType) => {
        if (productType.id == id) {
          name = productType.name;
        }
      });

      return name;
    },
    getCategoryName(id) {
      let name = "";
      this.categories.forEach((category) => {
        if (category.id == id) {
          name = category.name;
        }
      });

      return name;
    },
    getMaterialName(id) {
      let name = "";
      this.materials.forEach((material) => {
        if (material.id == id) {
          name = material.name;
        }
      });

      return name;
    },
    getCurrencyName(code) {
      let name = "";
      this.currencies.forEach((currency) => {
        if (currency.currency_code == code) {
          name = currency.display_name;
        }
      });

      return name;
    },
    isAssignedSupplier(supplierId) {
      const supplier = this.assignedSuppliers.find(
        (supplier) => supplier.supplier_id === supplierId
      );
      return supplier !== undefined;
    },
    assign(supplier) {
      this.saving = true;
      const data = {
        supplier_id: supplier.id,
      };

      const supplierName = supplier.first_name + " " + supplier.last_name;

      restAdapter
        .post(`/api/inquiries/${this.model.id}/assign_supplier`, data)
        .then((response) => {
          notification.success(supplierName + " assigned successfully");
          this.$emit("supplier-assigned", 1);
        })
        .then(() => {
          this.getInquiry(this.model.id).then((response) => {
            this.assignedSuppliers = [];
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });
            this.saving = false;
          });
        });
    },
    unassign(supplier) {
      this.saving = true;
      const data = {
        supplier_id: supplier.id,
      };

      const supplierName = supplier.first_name + " " + supplier.last_name;

      restAdapter
        .delete(`/api/inquiries/${this.model.id}/remove_supplier`, data)
        .then((response) => {
          notification.success(supplierName + " unassigned successfully");
          this.$emit("supplier-assigned", 1);
        })
        .then(() => {
          this.getInquiry(this.model.id).then((response) => {
            this.assignedSuppliers = [];
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });

            this.saving = false;
          });
        });
    },
    loadPage(page) {
      this.currentPage = page;
      this.getSuppliers();
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier-form {
  margin-top: -30px;
  justify-content: center;
}

.empty-layout-item {
  margin-top: -48px;
}

.form-label {
  color: $primaryDark !important;
  font-weight: 400;
  text-align: right;
}

.form-value {
  color: $primaryDark;
  font-weight: 400;
  text-align: left;
}

.supplier-list {
  .md-title,
  .md-subhead {
    color: $primaryDark;
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
    background-color: $cardBackground;
  }
}

.supplier-search-bar {
  text-align: right;
  display: flex;
}
.search_class{
  width: 140px;
  height: 36px;
  border-radius: 3px;
}
.spinner-box {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 999;
}

.supplier-list-layout {
  padding: 10px 0 10px 15px;
}

.supplier-name {
  align-content: center !important;
  color: $primaryDark;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}

.card-style {
  margin-right: 10px;
  width: 513px;
  height: 52px;
  border-radius: 3px;
  border: 0.5px solid $primaryDarkest;
  background: $paginateBackground;
  display: flex;
  align-items: center;
  text-align: left;
}

.supplier-brand {
  color: $primaryDark;
  text-align: left;
}

.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: $spinnerStroke !important;
}

.list_color.md-selected::v-deep .md-list-item-text{
    color: $primaryDarkest !important;
}

.list_color.md-theme-default::v-deep .md-list-item-text{
    color: $primaryDarkest !important;
}

.list_color.md-selected::v-deep .md-checked.md-primary .md-checkbox-container {
    background-color:transparent !important;
    border-color: $primaryDarkest !important;
    border-radius: 4px !important;
}

.list_color::v-deep .md-checkbox.md-theme-default .md-checkbox-container {
    background-color:transparent !important;
    border-color: $primaryDarkest !important;
    border-radius: 4px !important;
}

.list_color.md-selected::v-deep .md-checkbox.md-theme-default.md-checked .md-checkbox-container:after {
    border-color: $primaryDarkest !important;
}

.supplier_roles_container{
  padding-top: 10px;
  margin-top: -10px !important;
  background-color: $popupWindow;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1);
  border-radius: 4.25385px;
}

.color_class{
  color: $primaryDarkest !important;
}

.md-field.md-theme-default:before{
    background-color: $primaryDarkest !important;
}

.text_color{
  justify-content: center !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 13.7378px;
  line-height: 116.1%;
  display: flex;
  align-items: center;
  color: $primaryDark !important;
}

.subheading_text{
  color: $primaryDark;
  font-weight: 600;
  margin-bottom:30px;
  margin-right: 180px;
  text-decoration-line: underline;
}
.assigned_suppliers_subheading_text{
  color: $assignButton;
  font-weight: 600;
  margin-bottom:30px;
  text-decoration-line: underline;
}

.arrow_icon{
  font-size: 25px;
  color:$primaryDarkest;
}

.back_btn_class{
  width: 149px;
  height: 36px;
  margin-top: -5px;
  margin-left: 17px;
  background-color: rgba(71, 48, 104, 0.1) !important;
  border-radius: 81.2308px;
}

.back_btn_class:hover{
  cursor: pointer;
}

.md-card{
  box-shadow: none !important;
}
.unassign_btn_class{
  margin-top: -2px;
  align-content: center !important;
  box-shadow: none !important;
  width: 117.81px;
  height: 32px;
  border-radius: 2.71875px;
}
.btn_class{
  margin-left: 35px;
  align-content: center !important;
  box-shadow: none !important;
  width: 117.81px;
  height: 32px;
  border-radius: 2.71875px;
}

.field_size{
  width: 25% !important;
  margin-right: 40px;
  margin-bottom: 50px;
}

.pagination-style{
  margin-top: 5px;;
  margin-left: 0px;
  width: 513px;
}

.supplier-brand-name {
  color: $primaryDark;
}
</style>
