<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <div class="section request-container">
          <div v-if="traceLayout && displayTraceContainer" class="request-item">
            <div>You have requested to include:</div>
            <div>
              <ul>
                <li>Care Instructions</li>
                <li>Composition</li>
                <li>Sustainability, Circularity and Repairs</li>
                <li v-if="traceLayout.supply_chain">Supply Chain</li>
                <li v-if="traceLayout.bom">Bill of Material</li>
              </ul>
            </div>
          </div>
          <div v-if="isPublished" class="request-item">
            <div>Published trace may include: (if available)</div>
            <div>
              <ul>
                <li>Care Instructions</li>
                <li>Composition</li>
                <li>Sustainability, Circularity and Repairs</li>
                <li
                  v-if="
                    traceLayout && traceLayout.supply_chain_published != null
                      ? traceLayout.supply_chain_published
                      : true
                  "
                >
                  Supply Chain
                </li>
                <li
                  v-if="
                    traceLayout && traceLayout.bom_published != null
                      ? traceLayout.bom_published
                      : false
                  "
                >
                Bill of Material
                </li>
              </ul>
            </div>
          </div>
          <div class="buttons-container">
            <md-button
              class="qr-code-button md-raised success"
              style="color: #EEF0FB;"
              v-if="!isPublished"
              @click="openQrCode"
              >QR Code</md-button>
            <md-button
              class="md-raised"
              style="background-color: #5BCDCB; color: #EEF0FB;"
              v-if="!needTraceApproval"
              @click="requestTraceLayout = true"
              >Select Theme</md-button
            >
            <md-button
              class="md-raised"
              style="background-color: #582963; color: #EEF0FB;"
              v-if="!isPublished && isAvailable"
              @click="submit('publish')"
              >Publish</md-button
            >
            <md-button
              class="md-raised"
              style="background-color: #582963; color: #EEF0FB;"
              v-if="isPublished && needTraceApproval"
              @click="submit('approve')"
              >Publish Changes</md-button
            >
            <md-button
              class="md-raised"
              style="background-color: #FA735F; color: #EEF0FB;"
              v-if="needTraceApproval"
              @click="userCommentClose = true"
              >Reject</md-button
            >
          </div>
        </div>
        <v-dialog
          v-model="qrDialog"
          width="unset"
          class="qr-code-dialog"
          >
          <qr-code
              class="qr-code-comp"
              :qr="qr"
              :styleNumber="styleNumber"
              :trackingCode="trackingCode"
              centerAlign
          />
        </v-dialog>
        <popup-window
          :active="requestTraceLayout"
          title="Select 360 Trace Theme"
          :hasClose="false"
          :hasBack="false"
          width="861px"
          max-width="95vw"
          titleStyle = "margin-left: 2%; font-weight: bold;"

          noMargin
          @popup-close="closeRequestLayoutWindow"
          @popup-back="closeRequestLayoutWindow"
        >
          <div>
            <div>
              <v-container style="padding: 0px 2%;">
                <p
                  class="text-left mb-1 formLabel font-weight-light grey--text text--darken-1  "
                >
                Select the information you need to display
                </p>
                <div>
                  <div  style="display: flex; justify-content: space-between; flex-wrap: wrap;">
                    <v-checkbox
                      v-model="selectLayout.displayCareIns"
                      label="Care Instructions"
                      disabled
                      dense
                    />
                    <v-checkbox
                      v-model="selectLayout.displayComposition"
                      label="Composition"
                      disabled
                      dense
                    />
                    <v-checkbox
                      v-model="selectLayout.displayCircularity"
                      label="Sustainability, Circularity and Repairs"
                      disabled
                      dense
                    />
                    <v-checkbox
                      v-model="selectLayout.displaySupplyChain"
                      label="Supply Chain"
                      color="#5B3D87"
                      dense
                    />
                    <v-checkbox
                      v-model="selectLayout.displayBom"
                      label="Bill of Material"
                      color="#5B3D87"
                      dense
                    />
                  </div>
                  <div style="display: flex; justify-content: center;">
                    <div class="trace-layout-preview-container">
                      <div
                        class="layout-preview-section"
                        @click="handlePreviewWindow(true)"
                      >
                        <div class="zoom-icon-container">
                          <v-icon>mdi-magnify-plus-outline</v-icon>
                        </div>
                        <img style="max-height: 54vh;" :src="selectedLayoutImage" />
                      </div>
                    </div>
                  </div>
                </div>
              </v-container>
            </div>
            <div class="mb-2 mt-2">
              <ui-button class="danger" @click="closeRequestLayoutWindow" title="Close"/>
              <ui-button class="primary" @click="requestTraceLayoutAsync" title="Submit"/>
            </div>
          </div>
        </popup-window>
        <popup-window
          :active="traceLayoutPreviewWindow"
          title="Selected Trace Layout Preview"
          :hasBack="false"
          width="900px"
          maxWidth="95vw"
          titleStyle="margin-left: 2%; font-weight: bold;"
          closeButtonStyle="margin: 5px; padding: 5px;"
          noMargin
          @popup-close="handlePreviewWindow(false)"
          @popup-back="handlePreviewWindow(false)"
        >
          <div class="zoomed-trace-layout-preview-container">
            <div
              style="width: fit-content; height: fit-content;"
              @dblclick="handlePreviewZoom"
            >
              <img
                id="trace-layout-preview"
                :src="selectedLayoutImage"
              />
            </div>
          </div>
        </popup-window>
        <v-dialog
          max-width="500"
          v-model="userCommentClose"
          content-class="v-dialog-custom"
        >
          <v-card>
            <v-card-title>
              <p class="display-2 black--text underline text-left">
                Provide the reason for rejecting
              </p>
            </v-card-title>
            <v-divider />
            <v-card-text height="1000px" class="pt-0">
              <v-container class="py-0">
                <v-form ref="rejectionComment" class="mt-5">
                  <v-row>
                    <v-col cols="12" md="12">
                      <p
                        class="text-left mb-1 ml-0 formLabel font-weight-light grey--text text--darken-1  "
                      >
                        Add your comment here.
                      </p>
                      <v-textarea
                        v-model="form.traceRejectedComment"
                        outlined
                        dense
                        color="purple darken-3"
                        :rules="rules.traceRejectCommentRules"
                      >
                        <span class="required-field">*</span>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <md-button
                class="md-raised md-primary"
                style="background-color: #582963; color: #EEF0FB;"
                @click="userCommentClose = false"
                >Close
              </md-button>
              <md-button
                class="md-raised md-primary"
                style="background-color: #582963; color: #EEF0FB;"
                @click="submitRejectionComment"
                >Submit
              </md-button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-if="displayTraceContainer" no-gutters>
      <v-col>
        <TraceMobile
          v-if="isSmallScreen"
          :customerWindow="true"
          :publicLink="isPublished ? publicLink : ''"
          :traceLink="traceLink"
          :linkClick="false"
          :currentVersion="currentVersion"
          @change-version="changeVersion"
          :showToggle="isPublished && needTraceApproval"
          :qr="isPublished ? qr : ''"
          :trackingCode="trackingCode"
          :isReviewComponentVisible="false"
        />
        <Trace
          v-else
          :customerWindow="true"
          :publicLink="isPublished ? publicLink : ''"
          :traceLink="traceLink"
          :linkClick="false"
          :currentVersion="currentVersion"
          @change-version="changeVersion"
          :showToggle="isPublished && needTraceApproval"
          :qr="isPublished ? qr : ''"
          :trackingCode="trackingCode"
          :isReviewComponentVisible="false"
        />
      </v-col>
    </v-row>
    <v-row v-if="isPublished" no-gutters>
      <v-col>
        <div
          class="grey lighten-5 section feedback-container"
          :style="!isSmallScreen ? 'padding: 20px;' : 'padding: 10px;'"
        >
          <div class="more-info-container">
            <div v-if="!isSmallScreen" class="icon-container">
              <ellipse-icon
                :width="'59px'"
                :height="'59px'"
                border="2px"
                variant="success"
              >
                <customer-feedback-icon />
              </ellipse-icon>
              <span class="more-info-title title-underline"
                >Customer Feedback</span
              >
            </div>
            <div v-else class="icon-container title-underline">
              <customer-feedback-icon fill="#70D0CE" :width="24" :height="24" />
              <span class="more-info-title">Customer Feedback</span>
            </div>
            <review
              :isMobile="isSmallScreen"
              :inquiryId="$route.params.id"
              :isAddReviewVisible="false"
              :isAuthorizedToChangeReviewStatus="true"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import notification from '../../notification';
import restAdapter from '../../restAdapter';
import Trace from '../../components/Inquiry/Trace.vue';
import TraceMobile from '../../components/Inquiry/TraceMobile.vue';
import Review from '../../components/Inquiry/Review.vue';
import {required} from "vuelidate/lib/validators";
import QrCode from '../../components/Inquiry/QrCode.vue';

export default {
  name: "CustomerTraceView",
  components:{
      Trace,
      TraceMobile,
      Review,
      QrCode,
  },
  props: {
    inquiryId: {
      type: Number,
      required: true,
    },
    displayTraceContainer: {
      type: Boolean,
      required: true,
    },
    traceLayout: {
      type: Object,
      default: null,
    },
  },

  watch: {
    selectLayout: {
      handler: function(value) {
        this.resetZoom();
      },
      deep: true,
    },
    traceLayout: {
      handler: function(value) {
        if (value) {
          this.selectLayout.displaySupplyChain = ( value.supply_chain && true ) || false;
          this.selectLayout.displayBom = ( value.bom && true ) || false;
        }
      },
      deep: true,
      immediate: true
    }
  },

  data: () => ({
    loading: true,
    origin: null,
    copied: false,
    styleNumber: null,
    isAvailable: false,
    isPublished: false,
    isRejected: false,
    needTraceApproval: false,
    userCommentClose: false,
    requestTraceLayout: false,
    traceLayoutPreviewWindow: false,
    currentVersion: true,
    form: {
      traceRejectedComment: null,
    },
    rules: {
      traceRejectCommentRules: [(value) => !!value || "Comment is required"],
    },
    publicLink: "",
    qr: "",
    trackingCode: "",
    size: window.innerWidth,
    qrDialog:false,
    selectLayout: {
      displayCareIns: true,
      displayComposition: true,
      displayCircularity: true,
      displaySupplyChain: true,
      displayBom: true,
    },
    layoutImages: {
      withBom: require("../../assets/trace-layouts/with_bom.png"),
      withSupplyChain: require("../../assets/trace-layouts/with_supply_chain.png"),
      withBomAndSupplyChain: require("../../assets/trace-layouts/with_bom_and_supply_chain.png"),
      withoutBomAndSupplyChain: require("../../assets/trace-layouts/without_bom_and_supply_chain.png"),
    },
  }),

  validations: {
    form: {
      traceRejectedComment: { required },
    },
  },

  beforeMount() {
    this.origin = window.location.origin;
    this.getTraceStatus();
  },

  computed: {
    isSmallScreen() {
      return this.size <= 750;
    },
    traceLink() {
      if (this.isPublished && this.currentVersion) {
        return "/api/inquiries/trace/" + this.styleNumber;
      } else {
        return "/api/inquiries/trace/preview/" + this.inquiryId;
      }
    },
    selectedLayoutImage() {
      if (this.selectLayout.displayBom && this.selectLayout.displaySupplyChain)
        return this.layoutImages.withBomAndSupplyChain;
      else if (this.selectLayout.displayBom) return this.layoutImages.withBom;
      else if (this.selectLayout.displaySupplyChain)
        return this.layoutImages.withSupplyChain;
      else return this.layoutImages.withoutBomAndSupplyChain;
    },
  },

  created() {
    window.addEventListener("resize", this.screenSizeHandler);
  },

  destroyed() {
    window.removeEventListener("resize", this.screenSizeHandler);
  },

  methods: {
    handlePreviewZoom() {
      const imageElement = document.getElementById("trace-layout-preview");
      let width = "100%";
      if (imageElement.style["max-width"] != "200%") {
        imageElement.style["width"] = width;
        width = "200%";
      }

      imageElement.style["max-width"] = width;
      imageElement.style["width"] = width;
    },
    handlePreviewWindow(value) {
      if (!value) {
        this.resetZoom();
      }
      this.traceLayoutPreviewWindow = value;
    },
    resetZoom() {
      const imageElement = document.getElementById("trace-layout-preview");
      imageElement.style["width"] = "100%";
      imageElement.style["max-width"] = "100%";
    },
    copying() {
      navigator.clipboard
        .writeText(document.getElementById("toCopy").textContent)
        .then(() => (this.copied = true));
    },
    getTraceStatus() {
      restAdapter
        .get(`/api/inquiry/${this.inquiryId}/trace/status`)
        .then((response) => {
          this.styleNumber = response.data.style_number;
          this.isAvailable = response.data.is_available;
          this.isPublished = response.data.is_published;
          this.needTraceApproval = response.data.need_approval;
          if (this.isPublished && !this.needTraceApproval) {
            this.changeVersion(true);
          }
          this.getPublicData();
          this.loading = false;
        })
        .catch((error) => {
          notification.error(error.response.data.error);
          this.loading = false;
        });
    },
    changeVersion(isCurrent) {
      this.currentVersion = isCurrent;
    },

    submitRejectionComment() {
      this.$refs.rejectionComment.validate();
      if (!this.form.traceRejectedComment) {
        return;
      }
      restAdapter
        .post(`/api/inquiry/${this.inquiryId}/trace/reject_publish`, {
          comment: this.form.traceRejectedComment,
        })
        .then(() => {
          this.needTraceApproval = false;
          notification.success("360 Trace rejected successfully.");
          this.isAvailable = this.isPublished;
          this.$emit("trace-rejected", this.isPublished);
          this.userCommentClose = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },

    getPublicData() {
      const defaultPublicUrl = `${this.origin}/#/trace/${this.styleNumber}`;
      this.publicLink = defaultPublicUrl;
      restAdapter
        .post("/api/trace/generate_qr", {
          publicUrl: defaultPublicUrl,
          inquiryId: this.inquiryId,
        })
        .then((response) => {
          this.qr = response.data.qr;
          this.trackingCode = response.data.tracking_code;
        });
    },

    screenSizeHandler(e) {
      this.size = window.innerWidth;
      return this.size;
    },

    closeRequestLayoutWindow() {
      this.requestTraceLayout = false;
    },

    submit(param) {
      if (param === "reject") {
        const answer = window.confirm(
          "Are you sure you want to reject the changes?"
        );
        if (!answer) return false;
      }
      if (param === "publish") {
        const answer = window.confirm("Are you sure you want to publish?");
        if (!answer) return false;
      }
      if (param === "reject_publish") {
        const answer = window.confirm("Are you sure you want to reject?");
        if (!answer) return false;
      }
      if (param === "approve") {
        const answer = window.confirm("Are you sure you want to publish?");
        if (!answer) return false;
      }
      restAdapter
        .get(`/api/inquiry/${this.inquiryId}/trace/${param}`)
        .then((response) => {
          if (param !== "reject") {
            this.$emit("layout-requested", response.data.requested_layout);
          }
          if (param === "publish") {
            this.isPublished = true;
            this.needTraceApproval = false;
            this.getPublicData();
            notification.success("360 Trace published successfully");
          } else {
            this.needTraceApproval = false;
          }
          if (param === "approve") {
            this.needTraceApproval = false;
            notification.success(
              "New changes for 360 Trace published successfully"
            );
            document
              .getElementById("consumer_iframe")
              .contentWindow.location.reload();
          }
          if (param === "reject") {
            this.needTraceApproval = false;
            notification.success("New changes for 360 Trace is rejected");
          }
        })
        .catch((error) => {
          notification.error(error.response.data.error);
        });
    },

    async requestTraceLayoutAsync() {
      this.requestTraceLayout = false;
      restAdapter
        .post(`/api/inquiry/${this.inquiryId}/trace/request`, {
          displaySupplyChain: this.selectLayout.displaySupplyChain,
          displayBom: this.selectLayout.displayBom,
        })
        .then((response) => {
          notification.success("Layout request sent successfully");
          this.$emit("layout-requested", response.data.requested_layout);
        })
        .catch(() => notification.error("Something went wrong"));
    },
    openQrCode(){
      this.qrDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  justify-content: right;
  display: flex;
  margin-bottom: 5px;
  margin-left: auto;
  margin-top: auto;
}
.v-dialog-custom {
  width: 50% !important;
}

.required-field {
  color: red;
  font-size: 20px;
}

.message-rejected {
  background-color: lightcoral;
  color: white !important;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.url-div {
  float: left;
}

.url-span {
  background-color: burlywood;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.more-info-title {
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #473068;
}

.title-underline {
  border-bottom: 1px solid #70d0ce;
  width: fit-content;
}

.more-info-container {
  margin-top: 0px;
  text-align: left;
  min-height: 50px;
  width: 95%;
}

.feedback-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.request-container {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.request-item {
  text-align: left;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  color: #473068;
  background: white;
  border-radius: 12px;
}

.trace-layout-preview-container {
  width: 50vh;
  max-width: 100%;
  max-height: 54vh;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.zoomed-trace-layout-preview-container {
  width: fit-content;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.layout-preview-section {
  position: relative;
  width: fit-content;
  max-height: 54vh;
  cursor: pointer;
}

.public-link-div{
  border: 0.5px solid;
  border-color: #501D5F !important;
  border-radius: 8px;
  padding: 6px;
  margin: 7px 0px;
}
.public-link-span{
  color : #C2AAE8;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.qr-code-comp {
  padding: 10px;
}
.qr-code-button {
  color: white !important;
  margin-right: 8px;
}
.zoom-icon-container{
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
}
.zoom-icon-container .v-icon{
  font-size:60px;
  color: $primary;
  opacity: 0.5;
}

@media only screen and (max-width: 600px) {
  .v-dialog-custom {
    width: 100% !important;
  }
  .request-container {
    flex-direction: column;
  }
  .buttons-container {
    flex-direction: column;
  }
}
</style>
