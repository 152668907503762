<template>
  <v-card v-if="timeline.length" class="mb-6">
    <div v-if="loading" class="py-3">
      <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <v-stepper v-else non-linear v-model="stepper" class="timeline_container">
      <v-stepper-header>
        <template v-for="(service, key) in timeline">
          <v-stepper-step
            editable
            :step="stepper !== key + 1 ? key + 1 : 'Crt.'"
            :key="service.id"
            :complete="completedPercentages[key] === 100"
            edit-icon="$complete"
            @click="
              subStepper = 0;
              isClicked = true;
              selectedSupplier = null;
              selectedStatus = null;
              stepper === key + 1 && (stepper = 0);
            "
            v-bind:class="{ clickedService: clickEvent(stepper, key) }"
          >
            <v-progress-circular
              class="mx-auto display-mobile"
              :rotate="180"
              :size="60"
              :width="7"
              :value="completedPercentages[key]"
              :color="
                checkDeadlinePassedService(service.deadline, key)
                  ? '#FC3766'
                  : checkDeadlineClose(service, key)
                  ? '#EA7A66'
                  : '#70D0CE'
              "
            >
              <div
                :class="
                  checkDeadlinePassedService(service.deadline, key)
                  ? 'deadline_passed_inside_circle'
                  : checkDeadlineClose(service, key)
                  ? 'deadline_close_inside_circle'
                  : 'completed_inside_circle'"
                >
                {{ completedPercentages[key] + "%" }}
              </div>
            </v-progress-circular>
            <div class="mx-auto main-service-header text-center">
              <div>
                <div
                  class="main-service-header"
                  v-bind:class="{ active: isActive }"
                >
                  <div style="margin-left:3px; margin-right:3px;">
                    <div class="sub_task_number">{{ service.order_number }}</div>
                    {{ service.service_data && service.service_data.name }}
                  </div>

                </div>
              </div>
              <small v-if="!isAllSubCompleted(service.sub_services)" class="mt-1" style="color:#473068; font-size: 10px;">{{ formatDate(service.deadline) }}</small>
              <small v-if="isAllSubCompleted(service.sub_services)" class="mt-1" style="color:#473068; font-size: 10px;">{{ formatDate(service.sub_services[service.sub_services.length-1].completed_at) }}</small>
            </div>
          </v-stepper-step>
          <v-divider
            v-if="key !== timeline.length - 1"
            :key="'step' + service.id"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          :step="key + 1"
          v-for="(service, key) in timeline"
          :key="'content' + service.id"
        >
          <!-- <div class="overline">Service</div>
          <h3 class="mb-4">
            {{ service.service_data && service.service_data.name }}
          </h3> -->
          <!-- sub service -->
          <v-card class="subtasks_circulars_container">
            <v-card-title class="subtask_titles"
            >{{ service.service_data && service.service_data.name }}
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="my-auto v-chip-color ml-6"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    v-if="userCheck"
                  >
                    <status-icon class="mr-1"/>
                    <span class="status_alignment">
                      Change Status
                    </span>
                  </v-chip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in statuses"
                    :key="i"
                    @click="changeStatusService(item.value, service)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-chip class="ml-6 v-chip-color" outlined>
                <alarm-icon v-if="completedPercentages[key] !== 100" class="mr-1"/>
                <alarm-icon v-else class="mr-1"/>
                <span class="status_alignment">
                  {{
                    calculateDaysTaken(service.created_at, key) !== "Completed"
                      ? calculateDaysTaken(service.created_at, key) + " taken"
                      : calculateDaysTaken(service.created_at, key)
                  }}
                </span>
              </v-chip
              >
            </v-card-title>

            <v-row class="mt-2 ml-2">
              <v-col cols="4" lg="3" md="3" sm="4">
                <div class="dates_container" v-if="!isAllSubCompleted(service.sub_services)">
                  <span class="subservices_dates_titles">Deadline:</span>
                  <span class="subservices_dates">
                     {{ formatDate(service.deadline) }} ({{
                      formatTimeAgo(service.deadline)
                    }})
                  </span>
                </div>
                <div class="dates_container" v-if="isAllSubCompleted(service.sub_services)">
                  <span class="subservices_dates_titles">Completed on:</span>
                  <span class="subservices_dates">
                    {{ formatDate(service.sub_services.slice(-1)[0].completed_at) }}
                  </span>
                </div>
              </v-col>
              <v-col cols="4" lg="3" md="3" sm="4"
              >
                <div class="dates_container">
                  <span class="subservices_dates_titles">Created at:</span>
                  <span class="subservices_dates">
                    {{ formatTime(service.created_at) }}
                  </span>
                </div>
              </v-col>
              <v-col cols="4" lg="3" md="3" sm="4">
                <div class="dates_container">
                  <span class="subservices_dates_titles">Updated at:</span>
                  <span class="subservices_dates">
                    {{ formatTime(service.updated_at) }}
                  </span>
                </div>
              </v-col>
              <v-progress-linear
                v-if="changingStatus"
                class="mb-2 ml-10 mr-10"
                color="#473068"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-row>

            <v-card-text>
              <v-stepper non-linear v-model="subStepper" vertical  class="timeline_container">
                <template v-for="(sub_service, key) in service.sub_services">
                  <v-stepper-step
                    editable
                    :step="subStepper !== key + 1 ? key + 1 : 'Crt.'"
                    :key="'substep' + sub_service.id"
                    :complete="sub_service.status === 3"
                    edit-icon="$complete"
                    @click="subStepperClick(sub_service.deadline, sub_service.time_period, key)"
                  >
                    <div class="sub-service-header">
                      <v-progress-circular
                        class="display-mobile"
                        :size="50"
                        :width="6"
                        value="100"
                        :color="
                          subStepper === key + 1
                            ? '#70D0CE'
                            : checkDeadlinePassedSubService(sub_service)
                            ? '#FC3766'
                            : '#70D0CE'
                        "
                      >
                        <div v-if="sub_service.status !== 3">{{ key + 1 }}</div>
                        <v-icon color="#70D0CE" v-else> mdi-check</v-icon>
                      </v-progress-circular>
                      <div class="my-auto sub-service-header-text">
                        <h4 class="sub-service-name">
                          {{
                            sub_service.sub_service_data &&
                            sub_service.sub_service_data.name
                          }}
                        </h4>

                        <h6
                          v-if="sub_service.completed_at !== null"
                          class="mt-1 sub-service-date"
                        >
                          {{
                            "Completed on " +
                            formatDate(sub_service.completed_at)
                          }}
                        </h6>
                        <h6 v-else class="mt-1 sub-service-date">
                          {{ "Deadline " + formatDate(sub_service.deadline) }}
                        </h6>
                      </div>

                      <v-menu bottom offset-y class="status-menu">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            class="v-chip-color"
                            :color="
                              sub_service.status === 3
                                ? '#492a67'
                                : sub_service.status === 2
                                ? '#c6a8ec'
                                : '#EA7A66'
                            "
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!statusUpdateCheck(sub_service.assignee_details)"
                            outlined
                          >
                            <status-icon
                              v-if="
                                statusUpdateCheck(sub_service.assignee_details)
                              "
                              class="mr-1"
                            />
                            <span class="status_alignment">
                              {{ sub_service.status_name }}
                            </span>
                          </v-chip>
                        </template>
                        <v-list
                          v-if="statusUpdateCheck(sub_service.assignee_details)"
                        >
                          <v-list-item
                            v-for="(item, i) in statuses"
                            :key="i"
                            @click="changeStatus(item.value, sub_service.id)"
                          >
                            <v-list-item-title>{{
                                item.text
                              }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-stepper-step>
                  <v-stepper-content
                    :step="key + 1"
                    :key="'subcontent' + sub_service.id"
                  >
                    <v-progress-linear
                      v-if="changingStep"
                      class="mb-2"
                      color="#473068"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>

                    <v-row>
                      <v-col cols="10">
                        <v-row>
                          <v-col class="days">
                            <div>
                              <div class="subservices_dates_titles">
                                Created at:
                              </div>
                              <div class="mt-1 subservices_dates">
                                {{ formatTime(sub_service.created_at) }}
                              </div>
                            </div>
                            <div class="material-icons display-icons">
                              arrow_forward_ios
                            </div>
                          </v-col>

                          <v-col class="days">
                            <div>
                              <div class="subservices_dates_titles">
                                Updated at:
                              </div>
                              <div class="mt-1 subservices_dates">
                                {{ formatTime(sub_service.updated_at) }}
                              </div>
                            </div>
                            <div
                              class="material-icons display-icons"
                            >
                              arrow_forward_ios
                            </div>
                          </v-col>

                          <v-col class="days">
                            <div>
                              <div class="subservices_dates_titles">
                                Deadline:
                              </div>
                              <div class="mt-1 subservices_dates">
                                {{ formatDate(sub_service.deadline) }} ({{
                                  formatTimeAgo(sub_service.deadline)
                                }})
                              </div>
                            </div>
                          </v-col>

                          <div class="material-icons display-icons" v-if="userCheck">
                            arrow_forward_ios
                          </div>
                          <v-col class="days" v-if="userCheck">
                            <div>
                              <div class="subservices_dates_titles">
                                Time period:
                              </div>
                              <div class="mt-1 subservices_dates">
                                <v-autocomplete
                                  :items="numberList()"
                                  v-model="newTimePeriod"
                                  @change="changeTimePeriod(sub_service)"
                                >
                                </v-autocomplete>
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <h5
                              style="color:#70D0CE;"
                              class="mb-2"
                              v-if="sub_service.assignee_details.length"
                            >
                              Assignees
                            </h5>

                            <v-chip
                              class="no_assignees_chip"
                              v-if="
                                sub_service.assignee_details.length === 0 &&
                                  userCheck
                              "
                              outlined
                            >
                              No assignees
                            </v-chip>

                            <v-chip
                              v-for="(assignedSupplier,
                              index) in sub_service.assignee_details"
                              :close="userCheck ? true : false"
                              :key="index"
                              @click:close="
                                unassignSubService(
                                  sub_service.id,
                                  assignedSupplier.id
                                )
                              "
                              class="mx-1 mt-1 assignee-v-chip-color"
                            >{{ assignedSupplier.first_name }}
                            </v-chip
                            >
                            <v-progress-linear
                              v-if="unassigning"
                              class="mt-1"
                              color="#473068"
                              indeterminate
                              rounded
                              height="6"
                            ></v-progress-linear
                            >
                          </v-col>
                          <v-col
                            v-if="userCheck" class="assign_supplier_content"
                          >
                            <v-select
                              color="#473068"
                              outlined
                              dense
                              loader-height="5"
                              class="mt-5 text-field-width"
                              v-model="selectedSupplier"
                              :items="
                                getUnassignedSuppliers(
                                  sub_service.assignee_details
                                )
                              "
                              item-text="supplier_data.full_name"
                              item-value="supplier_id"
                              label="Assign Accountable Person"
                              no-data-text="No suppliers left to assign"
                              multiple
                              @blur="assignSubService(sub_service.id)"
                              :loading="supplierLoading ? true : false"
                            ></v-select>
                          </v-col>
                          <v-col
                            v-if="userCheck"
                          >
                            <v-text-field
                              color="#473068"
                              dense
                              outlined
                              loader-height="5"
                              v-model="newDeadline"
                              @input="changeDeadline(sub_service)"
                              type="date"
                              hint="Please select a working day which is not lower than the previous subservice deadline."
                              class="mt-5 text-field-width "
                              label="Change Deadline"
                              :loading="deadlineChanging ? true : false"
                            ></v-text-field>
                          </v-col
                          >
                        </v-row>
                      </v-col>

                      <v-col lg="2" class="up-down-section" v-if="userCheck">
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }"> -->
                        <!-- <v-icon
                          @click="
                            changeOrderUp(sub_service, service.order_number)
                          "
                          v-if="subStepper !== 1"
                          x-large
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-chevron-triple-up</v-icon
                        > -->
                        <!-- </template>
                          <span>Move Up</span>
                        </v-tooltip> -->
                        <!-- <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }"> -->
                        <!-- <v-icon
                          class="mt-auto mb-3"
                          x-large
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            changeOrderDown(sub_service, service.order_number)
                          "
                          v-if="subStepper !== service.sub_services.length"
                        >
                          mdi-chevron-triple-down</v-icon
                        > -->
                        <!-- </template>
                          <span>Move Down</span>
                        </v-tooltip> -->
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </template>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import momentBusiness from "moment-business-days";
import * as _ from "lodash"

export default {
  name: "Timeline",
  props: {
    collectionId: {
      type: [String, Number],
      required: true,
    },
    accountManager: {
      type: Boolean,
      default: false,
    },
    refreshTimeline: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    this.loading = true;
    if (this.collectionId) {
      await this.fetchTimeline();
      await this.fetchCollectionUsers()
        .then()
        .catch((error) => (this.loading = false));
    }
    this.loading = false;
  },
  data: () => ({
    value: 10,
    timeline: [],
    allServices: [],
    suppliers: [],
    selectedSupplier: null,
    stepper: 0,
    supplierLoading: false,
    isActive: true,
    changingStep: false,
    unassigning: false,
    deadlineChanging: false,
    isClicked: false,
    subStepper: 0,
    newDeadline: null,
    selectedStatus: null,
    changingStatus: false,
    statuses: [
      {text: "Not Started", value: 1},
      {text: "In Progress", value: 2},
      {text: "Completed", value: 3},
    ],
    loading: false,
    timePeriod: null,
    newTimePeriod: null,
  }),
  computed: {
    userCheck() {
      if (this.accountManager) {
        return true;
      }
      return false;
    },
    completedPercentages() {
      return this.timeline.map((service) => {
        const completedSubServices = service.sub_services.reduce(
          (total, current) => {
            return current.status === 3 ? total + 1 : total;
          },
          0
        );

        const percentage = Math.round(
          (completedSubServices / service.sub_services.length) * 100
        );

        return !isNaN(percentage) ? percentage : 0;
      });
    },
  },

  watch: {
    refreshTimeline: {
      handler: function(){
        this.loading = true;
        this.fetchTimeline();
        this.fetchCollectionUsers()
          .then(()=>this.loading = false)
          .catch(()=>this.loading = false)
      }
    }
  },

  methods: {
    clickEvent(stepper, key) {
      if (stepper === key + 1) {
        return true;
      } else return false;
    },
    checkDeadlineClose(service, key) {
      const deadline = this.$moment(service.deadline, "YYYY-MM-DD");
      const startDate = this.$moment(service.created_at, "YYYY-MM-DD");
      const today = this.$moment().format("YYYY-MM-DD");

      const totalDays = deadline.diff(startDate, "days");

      const elapsedTime = startDate.diff(today, "days");

      if (this.completedPercentages[key] !== 100) {
        if (totalDays - Math.abs(elapsedTime) <= (totalDays * 30) / 100) {
          return true;
        }
      }

      return false;
    },

    calculateDaysTaken(createdDate, key) {
      if (this.completedPercentages[key] !== 100) {
        const startDate = this.$moment(createdDate);
        const days = startDate.toNow(true);
        return days;
      }

      return "Completed";
    },

    changeStatusService(value, service) {
      const ids = [];

      this.selectedStatus = value;

      service.sub_services.map((item) => {
        ids.push(item.id);
      });

      const res = this.updateServiceStatus(ids);
    },
    checkDeadlinePassedService(serviceDeadline, key) {
      const deadline = this.$moment(serviceDeadline, "YYYY-MM-DD");
      const today = this.$moment().format("YYYY-MM-DD");

      const diff = deadline.diff(today, "days");

      if (diff < 0 && this.completedPercentages[key] !== 100) {
        return true;
      }

      return false;
    },
    checkDeadlinePassedSubService(service) {
      const deadline = this.$moment(service.deadline, "YYYY-MM-DD");
      const today = this.$moment().format("YYYY-MM-DD");

      const diff = deadline.diff(today, "days");

      if (diff < 0 && service.status !== 3) {
        return true;
      }

      return false;
    },
    subStepperClick(deadline, timePeriod, key) {
      this.subStepper === key + 1 && (this.subStepper = 0);
      this.selectedSupplier = null;
      this.newDeadline = deadline;
      this.timePeriod = timePeriod;
      this.newTimePeriod = timePeriod;
    },

    changeTimePeriod(subService){

      const start = this.$moment(subService.deadline, "YYYY-MM-DD");
      const businessDiff = parseInt(this.newTimePeriod) - parseInt(this.timePeriod);

      this.newDeadline = this.$moment(this.$moment(start, 'YYYY-MM-DD').businessAdd(businessDiff)._d).format('YYYY-MM-DD');

      this.changeSubServiceDeadline(null, businessDiff, subService);
    },

    changeDeadline(subService) {
      const start = this.$moment(subService.deadline, "YYYY-MM-DD");
      const end = this.$moment(this.newDeadline, "YYYY-MM-DD");
      const diff = end.diff(start, "days");

      let businessDiff = end.businessDiff(start, true);

      if((subService.time_period + businessDiff) < 0){
        businessDiff = subService.time_period ? -1 * parseInt(subService.time_period) : 0;
      }


      this.newDeadline = this.$moment(this.$moment(start, 'YYYY-MM-DD').businessAdd(businessDiff)._d).format('YYYY-MM-DD');

      this.newTimePeriod = subService.time_period + businessDiff;
      // this.newDeadline =
      // console.log('abc', this.$moment().add(subService.time_period + diff, 'days'));
      this.changeSubServiceDeadline(diff, businessDiff, subService);
    },
    statusUpdateCheck(assignees) {
      let result = false;

      assignees.map((assignee) => {
        if (assignee.id === auth.user.id) {
          result = true;
        }
      });

      if (this.accountManager) {
        result = true;
      }

      return result;
    },
    changeStatus(value, subServiceId) {
      this.selectedStatus = value;

      this.updateSubServiceStatus(subServiceId);
    },
    getUnassignedSuppliers(assignedSuppliers) {
      const newArr = [];
      assignedSuppliers.length &&
      assignedSuppliers.forEach((item) => {
        newArr.push(item.id);
      });

      const unassignedSuppliers = [];

      this.suppliers.length &&
      this.suppliers.filter((supplier) => {
        if (!newArr.includes(supplier.supplier_id)) {
          unassignedSuppliers.push(supplier);
        }
      });
      return unassignedSuppliers;
    },
    formatDate(date) {
      return this.$moment(date).format("Do MMMM YYYY");
    },
    formatTime(date) {
      return this.$moment(date).format("HH:mm Do MMMM YYYY");
    },
    formatTimeAgo(date) {
      return this.$moment(date).fromNow();
    },
    async fetchCollectionUsers() {
      const response = await restAdapter.get(
        `/api/collections/${this.collectionId}/users_list`
      );
      this.suppliers = response.data;
    },
    async fetchTimeline() {
      const response = await restAdapter.get(
        `/api/collections/${this.collectionId}/timeline`
      );
      this.timeline = response.data.services.filter((service) => {
        return service.type_name === "Primary Service";
      });
      this.allServices = response.data.services;

      this.unassigning = false;
      this.supplierLoading = false;
      this.changingStep = false;
      this.changingStatus = false;
    },

    async changeSubServiceDeadline(diff, businessDiff, sub_service) {
      const arr = [];

      this.timePeriod = sub_service.time_period + businessDiff;

      this.allServices.length && this.allServices.map((service) => {
        const subServices = [];

        service.sub_services.map((subService) => {
          if (subService.id === sub_service.id) {
            const subServiceData = {
              id: subService.sub_service_data.id,
              order_number: subService.order_number,
              time_period: subService.time_period + businessDiff,
            };

            subServices.push(subServiceData);
          } else {
            const subServiceData = {
              id: subService.sub_service_data.id,
              order_number: subService.order_number,
              time_period: subService.time_period,
            };

            subServices.push(subServiceData);
          }
        });

        const serviceData = {
          type: service.service_data.type,
          service_id: service.service_id,
          order_number: service.order_number,
          service_data: service.service_data,
          sub_services: subServices,
        };

        arr.push(serviceData);
      });

      await this.deadlineChange(arr);
    },

    async changeOrderDown(subService, serviceOrder) {
      this.changingStep = true;
      const arr = [];

      this.timeline.length &&
      this.timeline.map((service) => {
        const subServices = [];

        service.sub_services.map((subService) => {
          const subServiceData = {
            id: subService.sub_service_data.id,
            deadline: subService.deadline,
            order_number: subService.order_number,
            time_period: subService.time_period,
          };

          subServices.push(subServiceData);
        });

        const serviceData = {
          type: service.service_data.type,
          service_id: service.service_id,
          order_number: service.order_number,
          deadline: service.deadline,
          sub_services: subServices,
        };

        arr.push(serviceData);
      });

      const test = arr.map((service) => {
        if (service.order_number == serviceOrder) {
          service.sub_services[subService.order_number - 1].deadline =
            service.sub_services[subService.order_number].deadline;
          service.sub_services[subService.order_number - 1].order_number =
            service.sub_services[subService.order_number].order_number;

          service.sub_services[subService.order_number].deadline =
            subService.deadline;
          service.sub_services[subService.order_number].order_number =
            subService.order_number;
        }
      });

      await this.changeOrder(arr, "down");
    },

    changeOrderUp(subService, serviceOrder) {
      this.changingStep = true;
      const arr = [];

      this.timeline.length &&
      this.timeline.map((service) => {
        const subServices = [];

        service.sub_services.map((subService) => {
          const subServiceData = {
            id: subService.sub_service_data.id,
            deadline: subService.deadline,
            order_number: subService.order_number,
            time_period: subService.time_period,
          };

          subServices.push(subServiceData);
        });

        const serviceData = {
          type: service.service_data.type,
          service_id: service.service_id,
          order_number: service.order_number,
          deadline: service.deadline,
          sub_services: subServices,
        };

        arr.push(serviceData);
      });

      const test = arr.map((service) => {
        if (service.order_number == serviceOrder) {
          service.sub_services[subService.order_number - 1].deadline =
            service.sub_services[subService.order_number - 2].deadline;
          service.sub_services[subService.order_number - 1].order_number =
            service.sub_services[subService.order_number - 2].order_number;

          service.sub_services[subService.order_number - 2].deadline =
            subService.deadline;
          service.sub_services[subService.order_number - 2].order_number =
            subService.order_number;
        }
      });

      this.changeOrder(arr, "up");
    },

    async assignSubService(subserviceId) {
      if (this.selectedSupplier !== null) {
        this.supplierLoading = true;
      }

      this.selectedSupplier.forEach(async (item) => {
        const response = await restAdapter
          .post(
            `/api/collections/timeline/collection_sub_service/${subserviceId}/assign`,
            {
              assignee: item,
            }
          )
          .then((res) => {
            notification.success("Accountable person assigned successfully.");
            this.fetchTimeline();
            this.notifyCard();
          })
          .catch((error) => {
            notification.error(error.response.data.error);
          });
      });

      this.selectedSupplier = null;
    },
    async changeOrder(arr, direction) {
      const response = await restAdapter.put(
        `/api/collections/${this.collectionId}/timeline`,
        {
          services: arr,
        }
      );
      this.fetchTimeline().then((res) => {
        if (direction === "down") {
          this.subStepper = this.subStepper + 1;
          this.newDeadline = null;
          this.selectedSupplier = null;
        } else if (direction === "up") {
          this.subStepper = this.subStepper - 1;
          this.newDeadline = null;
          this.selectedSupplier = null;
        }
      });
    },
    async deadlineChange(arr) {
      this.deadlineChanging = true;
      const response = await restAdapter
        .put(`/api/collections/${this.collectionId}/timeline`, {
          services: arr,
        })
        .then((res) => {
          this.deadlineChanging = false;
          notification.success("Deadline changed successfully.");
          this.fetchTimeline();
          this.notifyCard();
        });
    },
    async unassignSubService(subserviceId, supplierId) {
      this.unassigning = true;
      const response = await restAdapter
        .delete(
          `/api/collections/timeline/collection_sub_service/${subserviceId}/remove_assignment`,
          {
            assignee: supplierId,
          }
        )
        .then((res) => {
          notification.success("Accountable person unassigned successfully.");
          this.fetchTimeline();
        })
        .catch((error) => {
          notification.error(error.response.data.error);
          this.unassigning = false;
        });
      this.notifyCard();
    },
    async updateSubServiceStatus(id) {
      console.log(this.selectedStatus);
      try {
        const response = await restAdapter.post(
          `/api/collections/timeline/collection_sub_service/${id}/updateStatus`,
          {
            status: this.selectedStatus,
          }
        );
      } catch(error){
        notification.error(error.response.data.message);
        this.fetchTimeline();
        this.selectedStatus = null;
        return null;
      }
      notification.success("Status updated successfully.");
      this.fetchTimeline();
      this.selectedStatus = null;
      this.notifyCard();
      this.notifyDashboard();
    },
    async updateServiceStatus(ids) {
      this.changingStatus = true;

      for (let index = 0; index < ids.length; index++) {
        try{
          const response = await restAdapter.post(
            `/api/collections/timeline/collection_sub_service/${ids[index]}/updateStatus`,
            {
              status: this.selectedStatus,
            }
          );
        } catch(error){
            this.fetchTimeline();
            notification.error(error.response.data.message);
            this.selectedStatus = null;
            return null;
        }
      }

      this.fetchTimeline();

      notification.success("Status updated successfully.");

      this.selectedStatus = null;
      this.notifyDashboard();
    },
    notifyDashboard() {
      this.$emit("reload-dashboard");
    },
    notifyCard(){
      this.$emit("reload-card");
    },
    isAllSubCompleted(subServices){
      const allNotCompleted = subServices.filter(item => item.status !== 3)
      if(!allNotCompleted.length){
        return true;
      }
      return false;
    },

    numberList(){
      return _.range(0,1000+1);
    }
  },
};
</script>
<style lang="scss">
.v-stepper__header .v-stepper__step__step {
  display: none !important;
}

// .v-text-field >>> input[type="date"]::-webkit-inner-spin-button,
// .v-text-field >>> input[type="date"]::-webkit-calendar-picker-indicator {
//   pointer-events: none !important;
//   -webkit-appearance: none !important;
// }

.clickedService {
  background: #eef1fa !important;
  border-radius: 5px;
  .main-service-header{
    color: #473068;
  }
}

.theme--light.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label {
    color: #473068;
}

.v-stepper__header .v-stepper__step {
  align-items: flex-start;
  padding: 5px;
}

.v-stepper__header {
  height: auto !important;
  margin-top: 5px !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.v-stepper__header .v-divider {
  border-width: 3px !important;
  border-color: #442D65  !important;
  background: #442D65;
  margin-bottom: 53px !important;
  border-width: 2px !important;
  // margin-bottom: 45px !important;
}

.v-stepper--vertical .v-stepper__content {
  border-left: 4px solid #70D0CE !important;
  // margin: -8px -36px -16px 52px !important;
  margin: -8px -36px -16px 47px !important;
  height: auto !important;
  border-width: 3px !important;
}

.v-stepper__step__step.primary {
  background: white !important;
  border: solid 8px #2196f3 !important;
}

.v-stepper--vertical .v-stepper__step__step {
  display: none !important;
  height: 50px !important;
  font-size: 15px !important;
  color: #2196f3 !important;
  background: white !important;
  border: solid 8px #2196f3 !important;
}

.v-icon.v-icon::after {
  background-color: white !important;
}

.main-service-header {
  display: grid;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #473068;
  width: 100px;
}

.sub-service-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-menu {
  z-index: 1;
}

.days {
  display: flex;
  justify-content: space-around;
}

.up-down-section {
  display: flex;
  flex-direction: column;
}

.v-stepper__content {
  top: 0;
  padding: 0;
  flex: 1 0 auto;
  width: 100%;
}

.sub-service-name-desktop {
  display: flex;
}

.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
  display: flex;
}

.display-icons {
  color: #70D0CE !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  font-weight: lighter;
}

.v-stepper__label {
  display: flex;
}

.sub-service-name {
  // font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 116.1%;
  color: #442D65;
}

.sub-service-date {
  // font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 116.1%;
  color: #473168 !important;
  display: flex;
}


.text-field-width {
  color:#473068;
  width: 180px !important;
}

.mdi-check {
  color: #70D0CE !important;
  font-size: 25px !important;
}

@media only screen and (min-width: 768px) {
  .sub-service-name-desktop {
    display: none;
  }

  .sub-service-header-text {
    width: 195px;
    display: grid;
    margin-left: 15px;
    margin-right: 30px;
  }

  .sub-service-name {
    display: unset;
  }

  .text-field-width {
    width: 90% !important;
  }

  .display {
    display: unset;
  }

  .v-card .v-card--raised {
    margin-top: 0 !important;
  }
}

.md-progress-spinner.md-theme-default .md-progress-spinner-circle{
  stroke: #492a67 !important;
}

.v-chip-color{
  font-style: normal;
  font-weight: 400;
  line-height: 116.1%;
  height: 27px !important;
  color:#473168 !important;
  background: rgba(200, 180, 230, 0.12) !important;
  border: 1px solid #473068 !important;
  border-radius: 46px;
}

.assignee-v-chip-color{
  font-style: normal;
  font-weight: 400;
  line-height: 116.1%;
  height: 27px !important;
  color:#473068 !important;
  background: #FAF7FF !important;
  border: 1px solid #473068 !important;
  border-radius: 46px;
}

.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
    color: #473068 !important;
}

.sub_task_number{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 9px;
  background: rgba(71, 48, 104, 0.1);
  border-radius: 50px;
  margin-left: auto;
  padding-top: 1px;
}

.subtask_titles{
  padding-top: 18px;
  font-style: normal;
  font-weight: 600;
  color: #442D65;
}
.dates_container{
  text-align: left;
}

.status_alignment {
    margin-top: 1px;
}

.subservices_dates_titles{
  text-align: left;
  margin-top:5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 116.1%;
  align-items: center;
  color: #442D65;
}

.subservices_dates{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 116.1%;
  color: #473168;
}

.deadline_passed_inside_circle{
    color: #FC3766;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 30px;
    z-index: 0;
    border: 1px solid #FFFFFF;
    box-shadow: -3px 4px 4px 0px rgb(234 122 102/ 33%);
    backdrop-filter: blur(5px);
}

.deadline_close_inside_circle{
    color: #EA7A66;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 30px;
    z-index: 0;
    border: 1px solid #FFFFFF;
    box-shadow: -3px 4px 4px 0px rgb(234 122 102 / 33%);
    backdrop-filter: blur(5px);
}

.completed_inside_circle{
    color: #70D0CE;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 30px;
    z-index: 0;
    border: 1px solid #FFFFFF;
    box-shadow: -3px 4px 4px 0px rgb(112 208 206 / 33%);
    backdrop-filter: blur(5px);
}

.v-progress-circular > svg {
  border: 1px solid #FFFFFF;
  border-radius: 50px;
}

.v-progress-circular__underlay {
  stroke: #F3F3F3 !important;
  z-index: 1;
}

.v-stepper__header{
  box-shadow: none !important;
}

.v-stepper{
  box-shadow: none !important;
}

.timeline_container{
  box-shadow: none !important;
  background-color: #FFFFFF !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.subtasks_circulars_container{
  margin: 18px;
  background-color: #F0F0F4 !important;
  box-shadow: none !important;
}

.status_colors{
  position: absolute;
  width: 99px;
  height: 24px;
  left: 397px;
  top: 472px;
  border: 0.870504px solid #473068;
  border-radius: 46px;
}

.no_assignees_chip{
  margin-top: 25px;
  width:150px;
  height: 32px !important;
  justify-content:center;
  color:#EA7A66 !important;
  border-color: #EA7A66 !important;
}

.list_color{
    color: #473068 !important;
}

.theme--light.v-stepper .v-stepper__step--editable:hover {
    background: #eef1fa !important;
    border-radius: 5px;
}
//text-field css changing <--
.theme--light.v-label {
    color: #473068;
}
//date color changed
.theme--light.v-input input, .theme--light.v-input textarea {
    color: #473068;
}
.theme--light.v-messages {
    color: #473068;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    color: #473068;

}
//-->
.assign_supplier_content::v-deep .v-menu__content {
  padding-top:40px;
  margin-top: 0px;
  color: #EA7A66 !important;
  position: absolute;
  display: inline-block;
  width: 180px !important;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  will-change: transform;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
}

//v-list color and background changed  <--
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #473068 !important;
}

.theme--light.v-list {
    background: #F3EDF7 !important;
}
//-->
</style>
