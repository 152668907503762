<template>
  <div v-on="subTaskWindow" class="table-container">
    <v-icon class="close-button" @click="closeWindow">close</v-icon>
    <v-row class=" table-row">
      <v-col>
        <table class="table-data">
          <thead>
          <tr>
            <th class="subtitle">Main Task</th>
            <th class="subtitle">Sub Task</th>
          </tr>
          </thead>
          <tbody v-for="mainTask in styleTaskList" v-bind:key="mainTask.id">
          <tr>
            <td class="table-data main-task"
                :class="insideMainTask == mainTask.id
                ? 'table-data-clicked'
                : 'table-data'"
                :rowspan="subTaskList.filter(task => mainTask.id == task.style_file_task_id).length + 1">
              {{ mainTask.name }}
            </td>
          </tr>
          <tr v-for="subTask in subTaskList.filter(task => mainTask.id == task.style_file_task_id)"
              v-bind:key="subTask.id">
            <td class="sub-task table-data"
                @click="subtaskClicked(subTask.style_file_task_id, subTask.id)"
            >
              <span>{{ subTask.name }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import restAdapter from "../../restAdapter";
import notification from "../../notification";
import auth from "@/auth";
// import { inquiryDataBus } from "../../utils/inquiryDataBus";

export default {
  name: "SelectStyleFileSubTask.vue",
  components: {},

  data: () => ({
    styleTaskList: [],
    subtaskClick: null,
    insideMainTask: null,
    subTaskList: [],
    collectionId:null,
  }),
  props: {
    isOwner: {
      type: Boolean,
    },
    subTaskWindow: {
      type: Boolean,
    },
    inquiryId: {
      type: Number,
    },
    file: {
      type: Object,
    },
  },

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  methods: {
    async initialize() {
      this.currentUserUserType = auth.user.userType;
      this.currentUserId = auth.user.id;
      this.collectionId = this.$route.params.id;
      this.getStyleFileTasks();
      this.getStyleFileSubTasks();
    },

    getStyleFileTasks() {
      restAdapter.get("/api/style_file/style_file_tasks").then((response) => {
        this.styleTaskList = response.data.style_file_task_data;
      });
    },

    closeWindow(){
      this.subTaskWindow = false;
      this.$emit("close");
    },

    getStyleFileSubTasks() {
      restAdapter.get("/api/style_file/style_file_sub_tasks").then((response) => {
          this.subTaskList = response.data.style_file_sub_task_data;
        });
    },

    subtaskClicked(taskId, subId) {
      this.subtaskClick = subId;
      this.insideMainTask = taskId;
      this.submit();
    },

    submit() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        inquiry_id: this.inquiryId,
        path: this.file.path,
        title: this.file.title,
        // eslint-disable-next-line @typescript-eslint/camelcase
        upload_name: this.file.upload_name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: this.currentUserId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        style_file_sub_task_id: this.subtaskClick,
        // eslint-disable-next-line @typescript-eslint/camelcase
        collection_id: this.collectionId,
      };
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/send_to_style_file/${this.subtaskClick}`, payLoad
        )
        .then((response) => {
          this.subtaskClick = null;
          console.log('abc',response.data.success);
          if(response.data.success === false){
            notification.error(
              "File already exists in selected sub task of style file"
            );
          } else {
            notification.success("Attachment sent to style file successfully");
          }
          this.$emit("close");
          // inquiryDataBus.$emit("reload-style-file");
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong");
          }
        });
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>

<style scoped>
table {
  width: 100%;
  height: 100%;
}

table td {
  text-align: left;
  border: 0.5px solid #473068;
  color: #442D65;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 14px;
}

td {
  height: 70px;
}

th {
  border: 0.5px solid #473068;
  text-align: center;
  height: 50px;
  background-color: #47C0BE;
  color: #442D65;
  font-weight: 600;
  font-size: 16px;
}

.subtitle {
  padding: 0px 15px;
}

.table-data {
  background-color: #FFFFFF;
  height: auto;
}

.table-row {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sub-task {
  cursor: pointer;
}

.sub-task:hover {
  background-color: #1ABC9C;
}

.close-button {
  position: relative;
  float: right;
  top: 5px;
  right: 5px;
}

@media only screen and (max-width: 900px) {
  .table-row {
    width: 1000px;
  }

  .table-container {
    padding-top: 15px;
    padding-right: 15px;
    overflow-x: scroll;
  }
}
</style>
